<template>
  <section>
    <b-modal
      id="modal-center"
      v-model="show"
      centered
      hide-footer
      size="xl"
      content-class="border-0 rounded-0"
      body-class="pb-5"
      header-class="border-bottom-0 pt-2 pb-2 mt-3"
      title="Available time slot"
      no-close-on-backdrop  
    >
      <LoadingSpinner v-if="isLoading" />
      <template v-slot:modal-header="">
        <template>
          <h1>Invitations</h1>
        </template>
        <b-button
          type="button"
          variant="default"
          class="p-0 ml-auto"
          @click="handleClose"
        >
          <icon-close
            height="24"
            width="24"
          />
        </b-button>
      </template>
      <template v-if="Object.keys(invitations).length">
        <div
          id="app"
          :style="Object.keys(invitations).length > 0 ? 'height:500px; overflow-y:auto' : ''"
        >
          <b-table-simple responsive>
            <b-tbody>
              <b-tr 
                v-for="(invite, idx) in invitations"
                :key="idx"
              >
                <b-td>
                  <span class="font-weight-bold">{{ moment(invite.date).format('dddd') }}</span>
                  <h4 class="font-weight-bold mb-0">
                    {{ moment(invite.date).format('MMMM DD') }}
                  </h4>
                  <small :class="[style['year-text'],'font-weight-light font-open-sans']">{{ moment(invite.date).format('YYYY') }}</small>
                </b-td>

                <b-table-simple responsive>
                  <b-tbody>
                    <b-tr>
                      <b-td class="font-weight-light text-capitalize">
                        Invitation from {{ invite.fab_name }}
                      </b-td>
                      <b-td class="font-weight-light">
                        {{ invite.call_type === 1 ? 'Video Call' : 'Streaming' }}
                      </b-td>
                      <b-td class="font-weight-light">
                        {{ moment(invite.start_time).format('hh:mm a') }} - {{ moment(invite.end_time).format('hh:mm a') }}
                      </b-td>
                      <b-td class="text-center">
                        <b-button
                          variant="light"
                          pill
                          :class="['text-uppercase border mr-2']"
                          @click="handleRejection(invite.invitation_id)"
                        >
                          Reject
                        </b-button> 
                        <b-button
                          variant="primary"
                          pill
                          :class="['text-uppercase']"
                          @click="handleRedirection(invite.slot_id, invite.invitation_id, invite.fab_id, invite.profile_pic_url, invite.fab_name)"
                        >
                          Accept
                        </b-button>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </template>
      <template v-else>
        <div class="text-center h4">
          No Invitations
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import IconClose from "../../assets/svg-import/close.svg";
import style from '../../styles/feed.module.scss';
import { rejectInvitation } from '../../api/meeting';
import LoadingSpinner from '../Common/LoadingSpinner';

export default {
    name: "Invites",
    components: { IconClose, LoadingSpinner },
    props: {
        show:{
            type:Boolean,
            default:false
        },
        totalInvites: {
            type: [Object, Array],
            default:()=>[],
        }
    },
    data() {
        return {
            style,
            profilePicFile: localStorage.getItem('user-image'),
            fabName: localStorage.getItem("fullname"),
            account_type: localStorage.getItem('account-type').toString(),
            fab_id: this.fabId,
            isDisabled: true,
            isLoading: false,
            items: [],
            invitations: this.totalInvites,
            newInvites: [],
        };
    },
    watch:{
        totalInvites: function(newVal){
            this.invitations = [];
            this.newInvites = [];
            newVal.map(value => {
                // var start_date = new Date(value.start_time.replace(/-/g, '/')+' UTC');
                // var end_date = new Date(value.end_time.replace(/-/g, '/')+' UTC');
                let ObjToPush = {
                    id: value.id,
                    date: value.date,
                    name: value.fabName,
                    start_time: value.start_time,
                    end_time: value.end_time,
                    invitation_id: value.invitation_id,
                    slot_id: value.slot_id,
                    fab_id: value.fab_id,
                    profile_pic_url: value.profile_pic_url,
                    fab_name: value.fab_name,
                    call_type: value.call_type
                };
                this.newInvites.push(ObjToPush);
            });
            this.invitations = this.newInvites;
        },
    },
    methods: {
        handleClose() {
            this.$emit("onClose");
            this.$emit("onSuccess");
        },
        async handleRejection(id){
            this.isLoading = true;
            let me = this;
            let rejected = await rejectInvitation(id);
            if (rejected.status){
                this.isLoading = false;
                this.invitations.findIndex(function(current, index){
                    if (current && current.invitation_id === id){
                        me.invitations.splice(index,1);
                    }
                });
            } else {
                this.isLoading = false;
            }
        },
        handleRedirection(id,invite_id, fab_id, profilePic, fabName){
            localStorage.setItem('fabName', fabName);
            localStorage.setItem('fabProfilePic', profilePic);
            localStorage.setItem('fabId', fab_id);
            let endodedId = window.btoa(id);
            let inviteId = window.btoa(invite_id);
            let objToSend = {
                slotId:endodedId,
                invitationId: inviteId
            };
            this.$router.push({path:'/booking/video', query: objToSend});
        }
    },
};
</script>
