<template>
  <div :class="[className, 'justify-content-center text-center text-white bg-dark px-4 py-4 mb-4']">
    <div class="text-center px-2">
      Tell us which items would you like to buy from POPZ
    </div>
    <b-input-group class="py-3">
      <b-form-input
        id="request_content"
        v-model="request_content"
        placeholder="Request item for sale"
        class="border-white bg-transparent font-weight-light py-2 shadow-none suggest-fab-search"
        type="text"
        style="color:white"
      />
    </b-input-group>
    <b-overlay
      :show="isLoading"
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      class="text-uppercase text-center text-white font-weight-bold"
    >
      <b-button
        ref="button"
        variant="dark"
        :class="['btn-pop text-uppercase font-weight-bold',]"
        squared
        block
        @click="sendRequest()"
      >
        Send
      </b-button>
    </b-overlay>
  </div>
</template>
<script>
import { _sendResponse } from "../../js/common-function";
import { addRequest } from '../../api/home';
export default {
    props: {
        className:{
            type: [String, Array],
            default: "",
        },
    },
    data() {
        return {
            request_content: '',
            isLoading: false,
            request_type: 1
        };
    },
    methods: {
        async sendRequest() {
            this.isLoading = true;
            if (this.request_content === ""){
                _sendResponse('error', 'Please enter request item correctly...');
                this.isLoading = false;
                return false;
            }

            let response = await addRequest('1', this.request_content);
            if (response){
                this.isLoading = false;
            }
        }
    }
};
</script>