<template>
  <div> 
    <div :class="[className, 'd-flex align-items-center']">
      <b-img
        fluid
        :class="['rounded-circle flex-shrink-0 mr-3 post-profile-pic', style['profile-pic']]"
        :src="profilePic"
        role="button"
        @click="redirectToProfile"
        @error="setPlaceholderProfileImg"
      />
      <div class="d-flex flex-column flex-grow-1">
        <span
          role="button"
          @click="redirectToProfile"
        >{{ postBy }}</span>
        <span class="font-open-sans">{{
          fromNowTime(new Date(postTime))
        }}</span>
        <span
          v-if="isExclusivePost===1"
          :class="[style['is-exclusive'], 'badge badge-pill']"
        >Exclusive</span>
      </div>
      <b-dropdown
        v-if="userId === postData.post_by_id"
        size="sm"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        :class="[style['edit-post-btn'], 'font-open-sans']"
      >
        <template #button-content>
          <div :class="[style['edit-menu'], 'mr-2']" />
        </template>
        <b-dropdown-item @click="showEditPopup(postData,postIndex)">
          Edit post
        </b-dropdown-item>
        <b-dropdown-item @click="showDeletePopup(postId,exclusive)">
          Delete
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { getFromNowTime, setPlaceholderProfileImg } from "../../js/common-function";
import style from "../../styles/feed.module.scss";
export default {
    props: {
        showDeletePopup:{
            type: Function,
            default:()=>{}
        },
        showEditPopup:{
            type: Function,
            default:()=>{}
        },
        postData:{
            type:[Object, Array],
            // eslint-disable-next-line vue/require-valid-default-prop
            default:()=>{},
        },
        className: {
            type: String,
            default: ""
        },
        profilePic: {
            required: true,
            type: String
        },
        username: {
            required: true,
            type: String
        },
        postBy: {
            required: true,
            type: String
        },
        postId:{
            type:Number,
            default:null
        },
        postIndex:{
            type:Number,
            default:null
        },
        userId:{
            type:Number,
            default:null
        },
        accType:{
            type:String,
            default: ""
        },
        postTime: {
            required: true,
            type: [String, Date]
        },
        exclusive:{
            type:Number,
            default:0
        },
        isExclusivePost: {
            type: Number,
            default: 0,
        },
        postById: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            fromNowTime: getFromNowTime,
            style,
            setPlaceholderProfileImg
        };
    },
    methods: {
        redirectToProfile() {
            window.location.href = "/feed/"+this.username;
            localStorage.setItem("fabId", this.postById);
        }
    }
};
</script>
