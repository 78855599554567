<template>
  <div :class="[style['post'], className]">
    <div class="p-3">
      <post-author
        :username="postUsername"
        :postBy="postBy"
        :profilePic="userId === postById ? profilePicImage : postProfilePic"
        :postTime="postTime"
        :postId="postId"
        :showDeletePopup="showDeletePopup"
        :showEditPopup="showEditPopup"
        :accType="accountType"
        :postData="postData"
        :userId="userId"
        :postIndex="postIndex"
        :exclusive="exclusive"
        :isExclusivePost="isExclusivePost"
        :profilePicImage="profilePicImage"
        :postById="postById"
      />
      <!--Start if exclusive is false/0-->
      <template v-if="postType == 3">
        <div :class="['mt-3 ml-3 font-open-sans pre-formatted']">
          Book my exclusive private
          {{ slotDetails.call_type == 1 ? "Video Call" : "Live Streaming" }}.
          Here are the details :
          <b-row>
            <b-col cols="12">
              Date:
              <span class="font-weight-bold">{{
                moment(slotDetails.start_time).format("MMMM DD, YYYY")
              }}</span>
            </b-col>
            <b-col cols="12">
              Time:
              <span
                class="font-weight-bold"
              >{{ moment(slotDetails.start_time).format("h:mm a") }} -
                {{ moment(slotDetails.end_time).format("h:mm a") }}</span>
            </b-col>
            <b-col
              v-if="slotDetails.no_of_gab_allow"
              cols="12"
            >
              Slots open:
              <span class="font-weight-bold">{{
                slotDetails.no_of_gab_allow-slotDetails.bookings_count
              }}</span>
            </b-col>
            <b-col cols="12">
              <b-button
                variant="dark"
                pill
                :class="[
                  style['browse-fabs-button'],
                  'text-uppercase vertical-align-center my-2',
                ]"
                @click="bookSlot(slotDetails)"
              >
                Book Now
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
      <template v-else>
        <div
          v-if="exclusive === 0"
          class="mt-3"
        >
          <b-skeleton
            animation="wave"
            width="85%"
          />
          <b-skeleton
            animation="wave"
            width="70%"
          />
          <b-skeleton
            animation="wave"
            width="55%"
          />
        </div>
        <!--Start if exclusive is true/1-->
        <div
          v-if="exclusive === 1"
          :class="['mt-3 ml-3 font-open-sans pre-formatted']"
        >
          {{ caption1 !== "" ? caption1 : postCaption }}
        </div>
        <div
          v-if="showCaption"
          :class="['ml-3 font-open-sans pre-formatted']"
        >
          {{ caption2 }}
        </div>
        <div
          v-if="exclusive === 1"
          class="text-right"
        >
          <b-button
            v-if="postCaption && postCaption.length > 320"
            class="bg-transparent font-weight-bold shadow-none"
            variant="default"
            @click="() => (showCaption = !showCaption)"
          >
            Read {{ showCaption ? "less" : "more" }}&gt;
          </b-button>
        </div>
      </template>
      <!--Stop -->
    </div>
    <!--Start if exclusive is false/0-->
    <template v-if="exclusive === 0 && postType != 3">
      <div :class="[style['comment-wrapper']]">
        <div class="d-flex p-3">
          <div class="flex-grow-1 d-flex mr-2 mr-sm-3">
            <b-button
              ref="button"
              variant="dark"
              :class="[
                style['exclusive-content-btn'],
                'btn-pop text-uppercase font-weight-bold',
              ]"
              squared
              block
              @click="
                handleRedirect(postBy, postProfilePic, postById, postUsername)
              "
            >
              Get Exclusive Content
            </b-button>
          </div>
          <div
            :class="[
              'flex-shrink-0 d-flex align-items-center justify-content-end',
              style['love-count-wrapper'],
            ]"
          >
            <span
              v-if="postLoves > 0"
              class="mr-2"
            >{{ postLoves }} loves</span>
          </div>
        </div>
      </div>
    </template>
    <!--Start if exclusive is true/1-->
    <template v-if="exclusive === 1">
      <div>
        <b-img
          v-if="includeVideo === false && postPic !== ''"
          :id="`postImage${postId}`"
          fluid
          class="post-caption-image"
          :src="postPic"
          @error="handleError(`postImage${postId}`)"
        />
        <div
          v-if="includeVideo"
          class="video-wrapper"
        >
          <video
            v-if="includeVideo && !videoError"
            :id="`video-file-${postId}`"
            style="width: 100%"
            class="video-file"
            @ended="handleVideoEnd(postId)"
          >
            <source
              :src="postPic"
              type="video/mp4"
              @error="handleVideoError(`video-file-${postId}`)"
            >
          </video>
          <div
            v-if="includeVideo && play"
            class="play"
            @click="handlePlayPause(postId)"
          />
          <div
            v-if="includeVideo && play === false && !videoError"
            class="pause"
            @click="handlePlayPause(postId)"
          />
          <b-img
            v-else-if="videoError"
            :id="`postImage${postId}`"
            fluid
            class="post-caption-image"
            :src="postPic"
            @error="handleVideoError(`postImage${postId}`)"
          />
        </div>
      </div>
      <div
        v-if="postType != 3"
        :class="[style['comment-wrapper']]"
      >
        <div
          v-if="postComments.length"
          class="p-3 border-bottom"
        >
          <div
            v-for="(comment, cIndex) in postComments"
            :key="comment.id"
            :class="[
              'd-flex',
              {
                'mb-3': cIndex < postComments.length - 1,
              },
            ]"
          >
            <img
              :src="
                userId == comment.author.id
                  ? profilePicImage
                  : comment.author.profile
              "
              :class="[
                'rounded-circle mr-2 flex-shrink-0',
                style['profile-pic'],
              ]"
              @error="setPlaceholderProfileImg"
            >
            <div :class="[style['content'], 'py-2 px-3 flex-grow-1']">
              <div>
                <div>
                  <strong class="mr-2 text-uppercase">{{
                    comment.author.username
                  }}</strong>
                  <span class="font-open-sans">{{ comment.comment }}</span>
                </div>
                <span class="font-open-sans">{{
                  fromNowTime(new Date(comment.commentTime))
                }}</span>
              </div>
              <b-badge
                pill
                :variant="comment.loveByUser ? 'primary' : 'white'"
                :class="[
                  style['comment-love'],
                  'py-1',
                  'border',
                  { 'border-primary': comment.loveByUser },
                ]"
                role="button"
                @click="toggleCommentLove(comment.id, postId)"
              >
                {{ comment.loves > 0 ? comment.loves : "" }}
                <icon-heart
                  width="15"
                  height="15"
                  fill="#fff"
                  :class="[{ 'stroke-primary': comment.loveByUser }]"
                />
              </b-badge>
            </div>
          </div>
          <template v-if="showMore">
            <div
              v-for="(comment, cIndex) in postComments2"
              :key="comment.id"
              :class="[
                'd-flex',
                'mt-3',
                {
                  'mb-3': cIndex < postComments2.length - 1,
                },
              ]"
            >
              <img
                :src="
                  userId == comment.author.id
                    ? profilePicImage
                    : comment.author.profile
                "
                :class="[
                  'rounded-circle mr-2 flex-shrink-0',
                  style['profile-pic'],
                ]"
                @error="setPlaceholderProfileImg"
              >

              <div :class="[style['content'], 'py-2 px-3 flex-grow-1']">
                <div>
                  <div>
                    <strong class="mr-2 text-uppercase">{{
                      comment.author.username
                    }}</strong>
                    <span class="font-open-sans">{{ comment.comment }}</span>
                  </div>
                  <span class="font-open-sans">{{
                    fromNowTime(new Date(comment.commentTime))
                  }}</span>
                </div>
                <b-badge
                  pill
                  :variant="comment.loveByUser ? 'primary' : 'white'"
                  :class="[
                    style['comment-love'],
                    'py-1',
                    'border',
                    { 'border-primary': comment.loveByUser },
                  ]"
                  role="button"
                  @click="toggleCommentLove(comment.id, postId)"
                >
                  {{ comment.loves > 0 ? comment.loves : "" }}
                  <icon-heart
                    width="15"
                    height="15"
                    fill="#fff"
                    :class="[{ 'stroke-primary': comment.loveByUser }]"
                  />
                </b-badge>
              </div>
            </div>
          </template>
          <div
            v-if="comments.length > 3"
            class="text-right mt-3 font-open-sans"
          >
            <a
              class="bg-transparent"
              @click="showMore ? (showMore = false) : (showMore = true)"
            >{{ showMore ? "Show less" : "Show more" }}</a>
          </div>
        </div>
        <div class="d-flex p-3">
          <div class="flex-grow-1 d-flex mr-2 mr-sm-3">
            <b-img
              id="author-profile-pic"
              fluid
              :src="profilePicImage"
              :alt="username"
              :class="[
                'rounded-circle mr-2 author-profile-pic',
                style['profile-pic'],
              ]"
              @error="setPlaceholderProfileImg"
            />
            <b-form-group
              class="flex-grow-1 mb-0"
              label-for="user_comment"
            >
              <b-form-input
                :id="`user_comment_${postId}`"
                v-model="userCommentInput"
                class="py-2 shadow-none"
                :name="`user_comment_${postId}`"
                placeholder="Leave a comment"
                @change="(e) => addPostComment(postId, e)"
              />
            </b-form-group>
          </div>
          <div
            :class="[
              'flex-shrink-0 d-flex align-items-center justify-content-end',
              style['love-count-wrapper'],
            ]"
          >
            <span
              v-if="postLoves > 0"
              class="mr-2"
            >{{ postLoves }} loves</span>
            <b-button
              variant="default"
              class="p-0 shadow-none"
              @click="toggleLove(postId)"
            >
              <icon-heart
                width="30"
                height="30"
                :class="[{ 'fill-primary stroke-primary': postUserLove }]"
              />
            </b-button>
          </div>
        </div>
      </div>
    </template>
    <!--Stop -->
  </div>
</template>

<script>
import PostAuthor from "./PostAuthor";
import iconHeart from "../../assets/svg-import/heart.svg";
import style from "../../styles/feed.module.scss";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import videoPlaceholder from "../../assets/images/videoPlaceholder.png";
import placeholderImage from "../../assets/images/post-placeholder.jpg";
import { APIUrlList } from "../../js/api_url";
import {
    _sendResponse,
    getFromNowTime,
    setPlaceholderProfileImg,
} from "../../js/common-function";

export default {
    components: {
        iconHeart,
        PostAuthor,
    },
    props: {
        getPostList: {
            type: Function,
            default: () => {},
        },
        postData: {
            type: [Object, Array],
            // eslint-disable-next-line vue/require-valid-default-prop
            default: () => {},
        },
        showDeletePopup: {
            type: Function,
            default: () => {},
        },
        showEditPopup: {
            type: Function,
            default: () => {},
        },
        updatePosts: {
            type: Function,
            default: () => {},
        },
        className: {
            type: [String, Array],
            default: "",
        },
        profilePic: {
            required: true,
            type: String,
        },
        username: {
            required: true,
            type: String,
        },
        postBy: {
            required: true,
            type: String,
        },
        postProfilePic: {
            required: true,
            type: String,
        },
        postId: {
            required: true,
            type: Number,
        },
        postIndex: {
            required: true,
            type: Number,
        },
        userId: {
            required: true,
            type: Number,
        },
        fabId: {
            type: Number,
            default: 0,
        },
        postById: {
            type: Number,
            default: 0,
        },
        postUsername: {
            required: true,
            type: String,
        },
        postTime: {
            required: true,
            type: [String, Date],
        },
        postPic: {
            required: true,
            type: String,
        },
        postCaption: {
            type: String,
            default: "",
        },
        loves: {
            type: Number,
            default: 0,
        },
        isExclusivePost: {
            type: Number,
            default: 0,
        },
        loveByUser: {
            type: Boolean,
            default: false,
        },
        comments: {
            type: Array,
            default: function () {
                return [];
            },
        },
        exclusive: {
            type: Number,
            default: 0,
        },
        accountType: {
            type: String,
            default: "",
        },
        postFees: {
            type: Object,
            default: () => {},
        },
        postType: {
            type: Number,
            default: 1,
        },
        slotDetails: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            style,
            postUserLove: this.loveByUser,
            postLoves: this.loves,
            postComments: [],
            postComments2: [],
            includeVideo: false,
            play: true,
            userCommentInput: "",
            showMore: false,
            fromNowTime: getFromNowTime,
            profilePicImage: localStorage.getItem("user-image"),
            caption1: "",
            caption2: "",
            showCaption: false,
            setPlaceholderProfileImg,
            placeholderImage,
            videoError: false,
        };
    },
    watch: {
        comments: function (newVal) {
            this.postComments = newVal.slice(0, 3);
            this.postComments2 = this.comments.slice(3);
        },
        profilePicImage: function (newVal) {
            this.profilePicImage = newVal;
        },
    },
    mounted() {
        window.addEventListener("user-image-localstorage-changed", (event) => {
            this.profilePicImage = event.detail.storage;
        });
    },
    created() {
        this.postComments = this.comments.slice(0, 3);
        this.postComments2 = this.comments.slice(3);
        this.includeVideo = this.postPic ? this.postPic.includes(".mp4") : false;
        if (this.postCaption && this.postCaption.length > 320) {
            this.caption1 = this.postCaption.slice(0, 320);
            this.caption2 = this.postCaption.slice(321);
        }
    },
    methods: {
        handleRedirect(postBy, profilePicImage, postById, fabUsername) {
            localStorage.setItem("fabName", postBy);
            localStorage.setItem("fabProfilePic", profilePicImage);
            localStorage.setItem("fabId", postById);
            localStorage.setItem("fabUsername", fabUsername);
            localStorage.setItem(
                "fabPostFees",
                this.postFees && this.postFees.fab_default_exclusive_post_fee
                    ? this.postFees.fab_default_exclusive_post_fee
                    : 0.0
            );
            this.$router.push("/booking/content");
        },
        toggleLove(postId) {
            var is_like = 1;
            if (this.postUserLove) {
                this.postUserLove = false;
                this.postLoves--;
                is_like = 0;
            } else {
                this.postUserLove = true;
                this.postLoves++;
            }
            var formdata = {
                is_like: is_like,
                like_type: 1,
                post_id: postId,
            };
            this.postCommentLike(formdata);
        },
        toggleCommentLove(commentId, postId) {
            let index = this.postComments.findIndex((element) => {
                return element.id === commentId;
            });
            if (index === -1) {
                var index2 = this.postComments2.findIndex((element) => {
                    return element.id === commentId;
                });
            }
            var is_like = 1;
            if (index !== -1) {
                if (this.postComments[index].loveByUser) {
                    this.postComments[index].loveByUser = false;
                    this.postComments[index].loves--;
                    is_like = 0;
                } else {
                    this.postComments[index].loveByUser = true;
                    this.postComments[index].loves++;
                }
            } else {
                if (this.postComments2[index2].loveByUser) {
                    this.postComments2[index2].loveByUser = false;
                    this.postComments2[index2].loves--;
                    is_like = 0;
                } else {
                    this.postComments2[index2].loveByUser = true;
                    this.postComments2[index2].loves++;
                }
            }
            var formdata = {
                is_like: is_like,
                like_type: 2,
                post_id: postId,
                comment_id: commentId,
            };
            this.postCommentLike(formdata);
        },
        handlePlayPause(postId) {
            let myVideo = document.getElementById("video-file-" + postId);
            if (myVideo.paused) {
                myVideo.play();
                this.play = false;
            } else {
                myVideo.pause();
                this.play = true;
            }
        },
        handleVideoEnd() {
            this.play = true;
        },
        postCommentLike(formdata) {
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .post(API_BASE_URL + APIUrlList.LIKEPOSTCOMMENT, formdata)
                .then(() => {})
                .catch((error) => {
                    console.error(error);
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                });
        },
        handleError(id) {
            document.getElementById(id).src = placeholderImage;
        },
        handleVideoError(id) {
            this.play = false;
            this.videoError = true;
            let videoElement = document.getElementById(id);
            if (videoElement !== null){
                document.getElementById(id).src = videoPlaceholder;
            }
        },
        addPostComment(postId, comment) {
            let formdata = {
                post_id: postId,
                comment: comment,
            };
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .post(API_BASE_URL + APIUrlList.ADDPOSTCOMMENTS, formdata)
                .then((response) => {
                    //this.getPostList();
                    this.updatePosts(response.data.data, "update", this.postIndex);
                    this.userCommentInput = "";
                })
                .catch((error) => {
                    console.error(error);
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                });
        },
        bookSlot(slots) {
            localStorage.setItem('fabName', slots.fab.name+' '+slots.fab.surname);
            localStorage.setItem('fabProfilePic', slots.fab.profile_pic_url);
            localStorage.setItem('fabId', slots.fab.id);
            let endodedId = window.btoa(slots.id);
            let objToSend = {
                slotId: endodedId,
            };
            this.$router.push({ path: "/booking/video", query: objToSend });
        },
    },
};
</script>
