<template>
  <section>
    <div
      v-if="fabId != ''"
      :class="style['description-wrapper']"
    >
      <div class="d-flex">
        <p class="mr-5">
          {{ displayTotalPosts }} posts
        </p>
        <p class="mr-5 d-none">
          {{ exclusivePost }} exclusive posts
        </p>
        <p
          :class="[style['cursor-pointer'], 'mr-5']"
          @click="
            () =>
              followersAccountType !== ''
                ? $router.push(`/followers-following/'${followersAccountType}`)
                : $router.push('/followers-following')
          "
        >
          {{ followers }} followers
        </p>
        <p
          :class="[style['cursor-pointer'], 'mr-5']"
          @click="
            () =>
              followersAccountType !== ''
                ? $router.push(`/followers-following/'${followersAccountType}`)
                : $router.push('/followers-following')
          "
        >
          {{ following }} following
        </p>
      </div>
      <p
        v-if="fabId"
        :class="[
          style['user-description'],
          'font-weight-bold mt-4 text-center pb-0 mb-1',
          [showFullDesc ? '' : 'text-truncate'],
        ]"
      >
        {{ about_you !== "null" ? about_you : "" }}
      </p>
      <p
        v-if="fabId && about_you.length > 36"
        :class="[style['read-more-less'], 'p-0 pr-3  mb-4 text-right']"
        @click="showDescription"
      >
        Read {{ showFullDesc ? "less" : "more" }}
      </p>
    </div>
    <b-row>
      <b-col
        offset-sm="2"
        cols="12"
      >
        <p
          :class="[style['user-description'], 'text-left']"
        >
          {{ fullName }}
        </p>
      </b-col>
      <b-col
        md="3"
        offset-md="3"
        class="mt-3"
      >
        <b-button
          v-if="account_type === '1' && fabId"
          ref="button"
          variant="dark"
          class="btn-pop text-uppercase font-weight-bold"
          squared
          block
          @click="followFab"
        >
          {{ isfollowing == null || isfollowing == 0 ? "FOLLOW" : "UNFOLLOW" }}
        </b-button>
      </b-col>
      <b-col
        md="5"
        class="mr-4 mt-3"
      >
        <b-button
          v-if="account_type === '1' && fabId"
          ref="button"
          variant="dark"
          class="btn-pop text-uppercase font-weight-bold mb-5"
          squared
          block
          @click="() => $router.push('/booking/content')"
        >
          Get Exclusive Content
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mx-0">
      <b-col cols="12">
        <live-stream :liveStreams="liveStreams" />
      </b-col>
    </b-row>
    <b-row class="mx-0">
      <b-col
        lg="6"
        sm="8"
      >
        <AddPost
          v-if="account_type === '2'"
          class="mb-4"
          :updatePosts="updatePosts"
        />
        <template v-if="posts.length">
          <div
            v-for="(post, pIndex) in posts"
            :key="`post${post.id}${pIndex}`"
          >
            <post
              :className="[
                {
                  'mb-4': pIndex < posts.length - 1,
                },
              ]"
              :postIndex="pIndex"
              :profilePic="profile"
              :loveByUser="post.loveByUser"
              :username="username"
              :postUsername="post.username"
              :postProfilePic="post.profile"
              :postTime="new Date(post.postTime)"
              :postCaption="post.postCaption"
              :postPic="post.postPic"
              :loves="post.loves"
              :comments="post.comments"
              :exclusive="post.exclusive"
              :postId="post.id"
              :getPostList="getPostList"
              :showDeletePopup="showDeletePopup"
              :showEditPopup="showEditPopup"
              :postData="post"
              :postBy="post.postBy"
              :userId="userId"
              :postType="post.post_type"
              :slotDetails="post.slotDetails"
              :updatePosts="updatePosts"
            />
          </div>
        </template>
        <template v-else>
          <no-post />
        </template>
      </b-col>
      <b-col
        lg="3"
        sm="4"
      >
        <p class="font-weight-bold">
          RECENTLY ADDED:
        </p>
        <shop-card-sm
          v-for="(product, pIndex) in products"
          :key="`product${pIndex}`"
          :productImage="product.image"
          :productTitle="product.title"
          :productPrice="product.price"
          :productId="product.id"
          :className="['d-block mt-2']"
        />
        <router-link
          v-if="products.length > 0"
          :to="`/products/${encodeFabId}`"
          class="px-0 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none"
        >
          See the store
        </router-link>
        <advertisement
          v-for="(advertisement, aIndex) in advertisements"
          :key="`advertisement${aIndex}`"
          :advImage="advertisement.advImage"
          :advText="advertisement.advText"
          :advId="advertisement.advId"
        />
        <h6 class="text-uppercase">
          Upcoming events
        </h6>
        <ul :class="[style['event-list'], 'list-unstyled']">
          <li
            v-for="(upcomingEvent, aIndex) in fabUpcomingList"
            :key="`upcomingevent${aIndex}`"
            :class="aIndex == 0 ? [style['active'], 'pl-3 mb-3'] : 'pl-3 mb-3'"
          >
            <strong class="small">
              {{ moment(aIndex).format("MM/DD") }}
            </strong>

            <div v-if="account_type === '1' && fabId != ''">
              <p
                v-for="bookslot of upcomingEvent"
                :key="bookslot.id"
                class="mb-0 font-open-sans"
              >
                {{ bookslot.start_time }}
              </p>
            </div>
            <div v-else>
              <p
                v-for="bookslot of upcomingEvent"
                :key="bookslot.id"
                class="mb-0 font-open-sans"
              >
                <span> {{ bookslot.name }} {{ bookslot.call_type }} </span>
              </p>
            </div>
          </li>
          <button
            v-if="account_type === '1' && fabId != '' && fabUpcomingList != ''"
            class="px-0 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none"
            @click="showSlotPopup()"
          >
            Show More
          </button>
        </ul>
        <h6
          v-if="
            (account_type === '1' && fab_invites.length > 0) ||
              Object.keys(fab_invites).length > 0
          "
          class="text-uppercase"
        >
          Invitations
        </h6>
        <ul
          v-if="fab_invites.length > 0 || Object.keys(fab_invites).length > 0"
          :class="[style['event-list'], 'list-unstyled']"
        >
          <li
            v-for="(invite, index) in fab_invites"
            :key="index"
            :class="index == 0 ? [style['active'], 'pl-3 mb-3'] : 'pl-3 mb-3'"
          >
            <strong class="small">
              {{ moment(invite.date).format("MM/DD") }}
            </strong>
            <div>
              <p class="mb-0 font-open-sans">
                Invitation from {{ invite.fab_name }}
              </p>
            </div>
          </li>
        </ul>
        <button
          v-if="totalInvitations.length > 0"
          class="px-0 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none"
          @click="$emit('handleInvitePopUp')"
        >
          Show More
        </button>
        <request-sale
          v-if="account_type === '1'"
          :className="['d-block mt-4']"
        />
      </b-col>
    </b-row>
  </section>
</template>
<script>
import Post from "../../components/Feed/Post";
import AddPost from "../../components/Feed/AddPost";
import ShopCardSm from "../../components/Feed/ShopCardSm";
import RequestSale from "../../components/Feed/RequestSale";
import NoPost from "../../components/Feed/NoPost";
import Advertisement from "../../components/Advertisements/Advertisement.vue";
import style from "../../styles/feed.module.scss";
import { dateFormat, timeFormat } from "../../js/common-function";
import LiveStream from "../../components/Feed/LiveStream.vue";

export default {
    components: {
        Post,
        AddPost,
        ShopCardSm,
        RequestSale,
        NoPost,
        Advertisement,
        LiveStream,
    },
    props: {
        totalPublicPost: {
            type: Number,
            required: true,
        },
        showDeletePopup: {
            type: Function,
            default: () => {},
        },
        showEditPopup: {
            type: Function,
            default: () => {},
        },
        totalExclusivePost: {
            type: Number,
            required: true,
        },
        totalFollowers: {
            type: [Number, String],
            required: true,
        },
        totalFollowing: {
            type: Number,
            required: true,
        },
        aboutFAB: {
            type: String,
            required: true,
        },
        postsData: {
            type: Array,
            required: true,
        },
        productsData: {
            type: Array,
            required: true,
        },
        adsData: {
            type: Array,
            required: true,
        },
        fabUpcomingListData: {
            type: [Array, Object],
            required: true,
        },
        profilePic: {
            type: String,
            required: true,
        },
        updatePosts: {
            type: Function,
            default: () => {},
        },
        getPostList: {
            type: Function,
            default: () => {},
        },
        getAdsList: {
            type: Function,
            default: () => {},
        },
        fabId: {
            type: Number,
            default: localStorage.getItem("fabId"),
        },
        userId: {
            type: [Number, String],
            default: "",
        },
        showSlotPopup: {
            type: Function,
            default: () => {},
        },
        invites: {
            type: [Object, Array],
            default: () => [],
        },
        totalInvites: {
            type: [Object, Array],
            default: () => [],
        },
        fullName: {
            type: String,
            default: "",
        },
        isfollowing: {
            type: Number,
            default: 0,
        },
        liveStreams: {
            type: Array,
            default: () => [],
        },
        displayTotalPosts: {
            type: [Number, String],
            default: "",
        },
    },
    data() {
        return {
            style,
            username: localStorage.getItem("username"),
            profile: this.profilePic,
            account_type: localStorage.getItem("account-type"),
            window: {
                width: 0,
                height: 0,
            },
            posts: this.postsData,
            products: this.productsData,
            fabUpcomingList: this.fabUpcomingListData,
            eventDateFormat: dateFormat,
            eventTimeFormat: timeFormat,
            advertisements: this.adsData,
            encodeFabId: window.btoa(localStorage.getItem("fabId")),
            publicPost: this.totalPublicPost,
            exclusivePost: this.totalExclusivePost,
            followers: this.totalFollowers,
            following: this.totalFollowing,
            about_you: this.aboutFAB,
            fab_invites: this.invites,
            totalInvitations: this.totalInvites,
            showFullDesc: false,
            followersAccountType: "",
        };
    },
    watch: {
        postsData: function(newVal) {
            if (Object.keys(this.posts) !== Object.keys(newVal)) {
                this.posts = newVal;
            }
            this.encodeFabId = window.btoa(localStorage.getItem("fabId"));
        },
        profilePic: function(newVal) {
            this.profile = newVal;
        },
        adsData: function(newVal) {
            this.advertisements = newVal;
        },
        totalPublicPost: function(newVal) {
            this.publicPost = newVal;
        },
        totalExclusivePost: function(newVal) {
            this.exclusivePost = newVal;
        },
        totalFollowers: function(newVal) {
            this.followers = newVal;
        },
        totalFollowing: function(newVal) {
            this.following = newVal;
        },
        aboutFAB: function(newVal) {
            this.about_you = newVal;
        },
        productsData: function(newVal) {
            this.products = newVal;
        },
        fabUpcomingListData: function(newVal) {
            this.fabUpcomingList = newVal;
        },
        invites: function(newVal) {
            this.fab_invites = newVal;
        },
        totalInvites: function(newVal) {
            this.totalInvitations = newVal;
        },
    },
    created: function() {
        if (
            this.account_type === "1" ||
      (this.account_type === "2" &&
        this.$route.params.username &&
        this.$route.params.username != this.username)
        ) {
            this.followersAccountType = this.$route.params.username;
        }
    },
    methods: {
        renderCover() {
            if (
                localStorage.getItem("account-type") !== null &&
        localStorage.getItem("account-type") != 1
            ) {
                return true;
            }
        },
        showDescription() {
            this.showFullDesc = !this.showFullDesc;
        },
        followFab() {
            this.$emit("changeFollowStatus");
        },
    },
};
</script>
