var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"text-right"},[(_vm.account_type === '1' && _vm.fabId != '')?_c('b-button',{ref:"button",class:[
        _vm.style['follow-btn'],
        'btn-pop text-uppercase font-weight-bold' ],attrs:{"variant":"dark","squared":"","block":""},on:{"click":_vm.followFab}},[_vm._v(" "+_vm._s(_vm.isfollowing == null || _vm.isfollowing == 0 ? "FOLLOW" : "UNFOLLOW")+" ")]):_vm._e()],1),(_vm.fabId)?_c('div',{staticClass:"d-flex flex-column text-center"},[_c('p',{staticClass:"pt-2 mr-3"},[_vm._v(" "+_vm._s(_vm.fullName)+" ")]),_c('p',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(_vm.about_you)+" ")]),_c('p',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(_vm.displayTotalPosts)+" posts ")]),_c('p',{staticClass:"mr-3 d-none"},[_vm._v(" "+_vm._s(_vm.exclusivePost)+" exclusive posts ")]),_c('p',{class:[_vm.style['cursor-pointer'], 'mr-3'],on:{"click":function () { return _vm.followersAccountType !== ''
            ? _vm.$router.push(("/followers-following/" + _vm.followersAccountType))
            : _vm.$router.push('/followers-following'); }}},[_vm._v(" "+_vm._s(_vm.followers)+" followers ")]),_c('p',{class:[_vm.style['cursor-pointer'], 'mr-3'],on:{"click":function () { return _vm.followersAccountType !== ''
            ? _vm.$router.push(("/followers-following/" + _vm.followersAccountType))
            : _vm.$router.push('/followers-following'); }}},[_vm._v(" "+_vm._s(_vm.following)+" following ")]),_c('p',[(_vm.account_type === '1')?_c('b-button',{ref:"button",class:[
          _vm.style['exclusive-content-btn'],
          'btn-pop text-uppercase font-weight-bold' ],attrs:{"variant":"dark","squared":"","block":""},on:{"click":function () { return _vm.$router.push('/booking/content'); }}},[_vm._v(" Get Exclusive Content ")]):_vm._e()],1)]):_vm._e(),_c('h6',{class:[_vm.style['upcoming-events'], 'text-uppercase'],on:{"click":_vm.handleUpcomingEvents}},[_vm._v(" Upcoming events "),_c('svg',{staticClass:"svg-inline--fa fa-caret-down fa-w-10",class:[_vm.style['select-svg'], 'mx-3'],attrs:{"aria-hidden":"true","focusable":"false","data-prefix":"fas","data-icon":"caret-down","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 320 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"}})])]),(_vm.show)?_c('ul',{class:[_vm.style['event-list'], 'list-unstyled']},[_vm._l((_vm.fabUpcomingList),function(upcomingEvent,aIndex){return _c('li',{key:("upcomingevent" + aIndex),class:aIndex == 0 ? [_vm.style['active'], 'pl-3 mb-3'] : 'pl-3 mb-3'},[_c('strong',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.moment(aIndex).format("MM/DD"))+" ")]),(_vm.account_type === '1' && _vm.fabId != '')?_c('div',_vm._l((upcomingEvent),function(bookslot){return _c('p',{key:bookslot.id,staticClass:"mb-0 font-open-sans"},[_vm._v(" "+_vm._s(bookslot.start_time)+" ")])}),0):_c('div',_vm._l((upcomingEvent),function(bookslot){return _c('p',{key:bookslot.id,staticClass:"mb-0 font-open-sans"},[(bookslot.call_type === 1)?_c('span',[_c('span',{staticClass:"font-weight-bold small"}),_vm._v(" "+_vm._s(bookslot.name)+" video call ")]):_c('span',[_vm._v(" "+_vm._s(bookslot.name)+" live streaming ")])])}),0)])}),(
        _vm.account_type === '1' && _vm.fabId != '' && _vm.fabUpcomingList != '' && _vm.show
      )?_c('button',{staticClass:"px-0 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none",on:{"click":function($event){return _vm.showSlotPopup()}}},[_vm._v(" Show More ")]):_vm._e()],2):_vm._e(),(
      (_vm.account_type === '1' && _vm.fab_invites.length > 0) ||
        Object.keys(_vm.fab_invites).length > 0
    )?_c('h6',{class:[_vm.style['upcoming-events'], 'text-uppercase'],on:{"click":_vm.handleInvitations}},[_vm._v(" Invitations "),_c('svg',{staticClass:"svg-inline--fa fa-caret-down fa-w-10",class:[_vm.style['select-svg'], 'mx-3'],attrs:{"aria-hidden":"true","focusable":"false","data-prefix":"fas","data-icon":"caret-down","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 320 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"}})])]):_vm._e(),(
      (_vm.fab_invites.length > 0 || Object.keys(_vm.fab_invites).length > 0) &&
        _vm.showInvites
    )?_c('ul',{class:[_vm.style['event-list'], 'list-unstyled']},_vm._l((_vm.fab_invites),function(invite,index){return _c('li',{key:index,class:index == 0 ? [_vm.style['active'], 'pl-3 mb-3'] : 'pl-3 mb-3'},[_c('strong',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.moment(invite.date).format("MM/DD"))+" ")]),_c('div',[_c('p',{staticClass:"mb-0 font-open-sans"},[_vm._v(" Invitation from "+_vm._s(invite.fab_name)+" ")])])])}),0):_vm._e(),(_vm.totalInvitations.length > 0 && _vm.showInvites)?_c('button',{staticClass:"px-0 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none",on:{"click":function($event){return _vm.$emit('handleInvitePopUp')}}},[_vm._v(" Show More ")]):_vm._e(),(_vm.products.length > 0)?_c('router-link',{staticClass:"px-2 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none",attrs:{"to":("/products/" + _vm.encodeFabId)}},[_vm._v(" See the store ")]):_vm._e(),(_vm.account_type === '2')?_c('AddPost',{staticClass:"mb-4",attrs:{"updatePosts":_vm.updatePosts}}):_vm._e(),_c('b-row',{staticClass:"mx-0"},[_c('b-col',{attrs:{"cols":"12"}},[_c('live-stream',{attrs:{"liveStreams":_vm.liveStreams}})],1)],1),(_vm.posts.length)?[_vm._l((_vm.firstPost),function(post,pIndex){return _c('div',{key:("post" + (post.id)),staticClass:"mt-3"},[_c('post',{attrs:{"className":[
          {
            'mb-4': pIndex < _vm.posts.length - 1,
          } ],"profilePic":_vm.profile,"loveByUser":post.loveByUser,"username":_vm.username,"postUsername":post.username,"postProfilePic":post.profile,"postTime":new Date(post.postTime),"postCaption":post.postCaption,"postPic":post.postPic,"loves":post.loves,"comments":post.comments,"exclusive":post.exclusive,"postId":post.id,"getPostList":_vm.getPostList,"postBy":post.postBy,"userId":_vm.userId,"postIndex":pIndex,"postData":_vm.firstPost,"slotDetails":post.slotDetails,"postType":post.post_type,"updatePosts":_vm.updatePosts}})],1)}),_vm._l((_vm.firstProduct),function(product,pIndex){return _c('shop-card-sm',{key:("product1_" + pIndex),attrs:{"productImage":product.image,"productTitle":product.title,"productPrice":product.price,"productId":product.id,"className":['d-block d-sm-block d-md-none mt-4']}})})]:_vm._e(),(_vm.posts.length)?[_vm._l((_vm.secondPost),function(post,pIndex){return _c('div',{key:("post" + (post.id))},[_c('post',{attrs:{"className":[
          {
            'mb-4': pIndex < _vm.posts.length - 1,
          } ],"profilePic":_vm.profile,"loveByUser":post.loveByUser,"username":_vm.username,"postUsername":post.username,"postProfilePic":post.profile,"postTime":new Date(post.postTime),"postCaption":post.postCaption,"postPic":post.postPic,"loves":post.loves,"comments":post.comments,"exclusive":post.exclusive,"postId":post.id,"getPostList":_vm.getPostList,"postBy":post.postBy,"userId":_vm.userId,"postIndex":pIndex,"postData":_vm.secondPost,"updatePosts":_vm.updatePosts}})],1)}),_vm._l((_vm.secondProduct),function(product,pIndex){return _c('shop-card-sm',{key:("product2_" + pIndex),attrs:{"productImage":product.image,"productTitle":product.title,"productPrice":product.price,"className":['d-block d-sm-block d-md-none mt-4']}})})]:_vm._e(),(_vm.posts.length)?_vm._l((_vm.remainingPosts),function(post,pIndex){return _c('div',{key:("post" + (post.id))},[_c('post',{attrs:{"className":[
          {
            'mb-4': pIndex < _vm.posts.length - 1,
          } ],"profilePic":_vm.profile,"loveByUser":post.loveByUser,"username":_vm.username,"postUsername":post.username,"postProfilePic":post.profile,"postTime":new Date(post.postTime),"postCaption":post.postCaption,"postPic":post.postPic,"loves":post.loves,"comments":post.comments,"exclusive":post.exclusive,"postId":post.id,"getPostList":_vm.getPostList,"showDeletePopup":_vm.showDeletePopup,"showEditPopup":_vm.showEditPopup,"postBy":post.postBy,"userId":_vm.userId,"postIndex":pIndex,"postData":_vm.remainingPosts,"updatePosts":_vm.updatePosts}})],1)}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }