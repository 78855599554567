<template>
  <b-modal
    id="modal-center"
    v-model="show"
    centered
    hide-footer
    size="md"
    body-class="pb-5"
    header-class="border-bottom-1 pt-2 pb-2"
    title="Edit Post"
  >
    <template v-slot:modal-header="">
      <span class="ml-auto">
        Edit Post
      </span>
      <b-button
        type="button"
        variant="default"
        class="p-0 ml-auto"
        @click="handleClose"
      >
        <icon-close
          height="24"
          width="24"
        />
      </b-button>
    </template>
    <b-row>
      <b-col md="2">
        <b-img
          fluid
          :class="['rounded-circle flex-shrink-0', style['edit-post-profile-pic']]"
          :src="postData.profile"
          @error="setPlaceholderProfileImg"
        />
      </b-col>
      <b-col md="8">
        <div>
          <p class="mb-0">
            {{ postData.username }}
          </p>
          <div :class="[style['select-wrapper']]">
            <select
              id="is_exclusive_post"
              v-model="is_exclusive_post"
              name="is_exclusive_post"
              :class="[style['edit-select-post'], 'mr-4']"
              @change="handlePostType"
            >
              <option value="0">
                Public Post
              </option>
              <option value="1">
                Exclusive Post
              </option>
            </select>
            <svg
              :class="[style['edit-select-svg'], 'mx-2']"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="caret-down"
              class="svg-inline--fa fa-caret-down fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                fill="currentColor"
                d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
              />
            </svg>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-form-textarea
        id="message"
        v-model="message"
        name="message"
        placeholder="How is your day?"
        :class="[style['post-text-area','edit-post-textarea'], 'no-resize', 'border-0']"
        rows="4"
        @keyup="changeMessage"
      />
      <div style="position:relative">
        <b-img
          v-if="!isVideo"
          fluid
          :src="postPic"
          :class="[style['edit-post-image'],'ml-4']"
        />
        <div v-else-if="postPic !== ''">
          <video
            :id="`video-file`"
            :class="[style['edit-post-image'],'ml-4']"
          >
            <source
              :src="postPic"
              type="video/mp4"
            >
          </video>
          <div
            v-if="isVideo && play"
            class="play"
            @click="handlePlayPause"
          />
          <div
            v-if="isVideo && !play"
            class="pause"
            @click="handlePlayPause"
          />
        </div>
        <button
          v-if="postPic !== ''"
          :class="style['edit-top-right']"
          @click="removePostPic"
        >
          x
        </button>
      </div>
      <div class="w-100 text-center">
        <b-row>
          <b-col>
            <label
              for="fileUpload"
              :class="style['upload-file']"
            >
              <b-button  
                :class="[style['edit-post-save-btn'],'mt-2']" 
                @click="handleFileOpen"
              >
                Browse
              </b-button>
            </label>
            <b-form-file
              id="fileUpload"
              type="file"
              name="profile-pic"
              class="d-none"
              @change="addPostPic"
            />
          </b-col>
          <b-col>
            <b-overlay
              :show="isLoading"
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="font-weight-bold bg-transparent h-100"
            >
              <b-button  
                :class="[style['edit-post-save-btn'],'mt-2']" 
                :disabled="isDisabled"
                @click="saveEditPost"
              >
                Save
              </b-button>
            </b-overlay>
          </b-col>
        </b-row>
      </div>
    </b-row>
  </b-modal>
</template>

<script>
import IconClose from "../../assets/svg-import/close.svg";
import style from '../../styles/feed.module.scss';
import axios from "axios";
import { API_BASE_URL, IMAGE_URL } from "../../config/config";
import { APIUrlList } from "../../js/api_url";
import { uploadToBucket, removeFromBucket, _sendResponse, setPlaceholderProfileImg } from "../../js/common-function";

export default {
    name: "EditPost",
    components: { IconClose },
    props: {
        show:{
            type:Boolean,
            default:false
        },
        updatePostIndex:{
            type:Number,
            default:null
        },
        postData:{
            type:Object,
            // eslint-disable-next-line vue/require-valid-default-prop
            default:{},
        },
        updatePosts:{
            type:Function,
            default:()=>{}
        }
    },
    data() {
        return {
            style,
            ProfileImage: "",
            postPic: "",
            is_exclusive_post:"",
            media_url: "",
            post_type: "",
            message:"",
            post_id:"",
            post_image: "",
            post_media: "",
            post_media_type: "image",
            isDisabled: true,
            isLoading: false,
            isImageChange: false,
            isVideo: false,
            play: true,
            username: localStorage.getItem('username'),
            setPlaceholderProfileImg
        };
    },
    watch:{
        postData:function(newVal){
            this.message=newVal.postCaption;
            this.post_id=newVal.id;
            this.is_exclusive_post = newVal.isExclusivePost;
            this.postPic = newVal.postPic;
            this.media_url = newVal.postPic;
            this.post_image = newVal.postPicPath;
            this.post_type = newVal.post_type;
            this.isVideo = newVal.postPic.includes('mp4') ? true : false;
        }
    },
    methods: {
        handleClose() {
            this.$emit("onClose");
        },
        handleDelete() {
            this.$emit("onClose");
        },
        handleFileOpen(){
            document.getElementById('fileUpload').click();
        },
        changeMessage() {
            this.message === "" ? this.isDisabled = true : this.isDisabled = false;
        },
        handlePlayPause() {
            let myVideo = document.getElementById("video-file");
            if (myVideo.paused) {
                myVideo.play();
                this.play = false;
            } else {
                myVideo.pause();
                this.play = true;
            }
        },
        addPostPic(e) {
            this.isDisabled = false;
            this.isVideo = e.target.files[0].type.includes('video') ? true : false;
            this.postPic = URL.createObjectURL(e.target.files[0]);
            this.post_media = e.target.files[0];
            this.post_type = 2;
            this.isImageChange = true;
            this.post_media_type = this.isVideo ? "video" : "image";
        },
        removePostPic() {
            this.postPic = "";
            this.post_media = "";
            this.post_type = 1;
            this.message === "" ? this.isDisabled = true : this.isDisabled = false;
            this.isImageChange = false;
            this.post_media_type = "";
        },
        async saveEditPost() {

            this.isLoading = true;

            if (this.post_type===2){
                if (this.isImageChange === true) {
                    if (this.post_image){
                        this.post_image = this.post_image.slice(1);
                        await removeFromBucket(this.post_image);
                    }
                    this.media_url = await uploadToBucket(this.post_media,"uploads/posts/");
                } else {
                    this.media_url = this.media_url.split(IMAGE_URL)[1];             
                }
            }

            var formData = {
                message: this.message,
                is_exclusive_post: this.is_exclusive_post,
                media_url: this.media_url,
                post_type: this.post_type,
                post_id: this.post_id,
                is_image_change: this.isImageChange,
                media_type: this.post_media_type
            };

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .post(API_BASE_URL + APIUrlList.ADDPOST, formData)
                .then(response => {
                    this.isLoading = false;
                    this.updatePosts(response.data.data,'update',this.updatePostIndex);
                    this.message = "";
                    this.postPic = "";
                    window.location.href = `/feed/${this.username}`;
                    this.handleClose();


                })
                .catch(error => {
                    console.error(error);
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                });

        },
        handlePostType(){
            this.isDisabled = this.post_type !== "" ? false : true;
        },
    },
};
</script>
