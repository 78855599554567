<template>
  <div
    v-if="liveStreams.length"
    class="py-4"
  >
    <b-button
      v-b-toggle.collapse-3
      class="m-1 btn-dark"
      @click="changeToggleState()"
    >
      Interactions
    </b-button>
    <b-collapse
      id="collapse-3"
      :visible="showStreamList"
    >
      <div class="py-3">
        <b-row class="mx-0">
          <b-col
            v-for="stream in liveStreams"
            :key="stream.id"
            xl="3"
            md="4"
            sm="12"
            cols="12"
            class="mb-2 px-1"
          >
            <template v-if="account_type == 1">
              <b-button
                class="btn btn-golden text-white"
                :disabled="stream.remaining_time.minutes > 5"
                @click="handleCall(stream)"
              >
                <div>
                  Join
                  {{
                    stream.call_type == "1"
                      ? "Video  Call "
                      : "Live Streaming"
                  }}
                </div>
                <div v-if="stream.fab">
                  <small>with {{ stream.fab.name }} {{ stream.fab.surname }}</small>
                </div>
                <div>
                  <small>{{ stream.start_original_date }}</small>
                </div>
                <div>
                  <small>{{ stream.start_original_time }}</small>
                </div>
              </b-button>
            </template>
            <template v-if="account_type == 2">
              <b-button
                class="btn btn-golden text-white"
                :disabled="stream.remaining_time.minutes > 5"
                @click="handleCall(stream)"
              >
                <div v-if="stream.booking">
                  {{
                    stream.booking.celebrity_id != userId ? "Join" : "Start"
                  }}
                  {{
                    stream.booking.call_type == "1"
                      ? "Video Call"
                      : "Live Streaming"
                  }}
                </div>
                <div v-if="stream.fab && stream.booking && stream.booking.celebrity_id != userId">
                  <small>with {{ stream.fab.name }} {{ stream.fab.surname }}</small>
                </div>
                <div v-if="stream.stream_type != ''">
                  <small>{{ stream.stream_type }}</small>
                </div>
                <div v-else>
                  <small>-</small>
                </div>
                <div>
                  <small>{{ stream.start_original_date }}</small>
                </div>
                <div>
                  <small>{{ stream.start_original_time }}</small>
                </div>
              </b-button>
            </template>
          </b-col>
        </b-row>
      </div>
    </b-collapse>
  </div>
</template>
<script>
export default {
    props: {
        liveStreams: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            account_type: localStorage.getItem("account-type"),
            userId: localStorage.getItem("userId"),
            showStreamList: JSON.parse(localStorage.getItem("showStreams")),
        };
    },
    computed: {
        fabId() {
            return localStorage.getItem("fabId");
        },
    },
    methods: {
        handleCall(data) {
            this.$router.push({ path: `/call/${data.booking.id}` });
        },
        changeToggleState() {
            if (this.showStreamList == true) {
                localStorage.setItem("showStreams", JSON.stringify(false));
            } else if (this.showStreamList == false) {
                localStorage.setItem("showStreams", JSON.stringify(true));
            }
        },
    },
};
</script>
