<template>
  <div :class="className">
    <b-img
      fluid
      class="rounded-circle user-profile-pic"
      :src="profilePic"
      @error="setPlaceholderProfileImg"
    />
    <h5 class="mt-3">
      {{ fullname }}
    </h5>
  </div>
</template>

<script>
import { setPlaceholderProfileImg } from "../../js/common-function";
export default {
    props: {
        className: {
            type: String,
            default: "",
        },
        profilePic: {
            required: true,
            type: String,
        },
        username: {
            required: true,
            type: String,
        },
        fullname: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            setPlaceholderProfileImg,
        };
    },
};
</script>
