<template>
  <section>
    <LoadingSpinner v-if="loading" />
    <b-modal
      id="modal-addslot"
      v-model="show"
      centered
      size="lg"
      header-class="border-bottom-0"
      modal-class="addslot-modal"
      :no-close-on-backdrop="true"
    >
      <LoadingSpinner v-if="loading" />
      <template
        v-slot:modal-header=""
      >
        <h4 class="font-weight-bold">
          Add new slot
        </h4>
      
        <b-button
          type="button"
          variant="default"
          class="p-0 ml-auto"
          @click="handleClose"
        >
          <icon-close
            height="24"
            width="24"
          />
        </b-button>
      </template>

      <b-container
        fluid
        class="pl-3 pr-3"
      >
        <b-form>
          <b-row class="mb-1">
            <b-col cols="6">
              <div
                class="font-open-sans mb-2"
              >
                <div class="font-weight-600">
                  Fans
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <Select2 
                      id="fans"
                      v-model="fans" 
                      v-validate="{required: true,}" 
                      :settings="{ multiple: true }"
                      :state="validateState('fans')"
                      name="fans"
                      :class="['bg-transparent', 'border', style['field']]" 
                      :options="fansList" 
                      placeholder="Select Fans"
                      aria-describedby="input-1-live-fans"
                      data-vv-as="Fans"
                      style="width:100%"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-fans"
                    class="valdiation_error"
                  >
                    {{
                      veeErrors.first("fans")
                    }}
                  </span>
                </div>
              </div>
              <div
                v-if="type === 'call'"
                class="font-open-sans mb-2"
              >
                <div class="font-weight-600">
                  Video Slots
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-select
                      id="videoslots"
                      v-model="videoslots"
                      v-validate="{ required: true }"
                      :state="validateState('videoslots')"
                      :options="options"
                      name="videoslots"
                      :class="[style['field'], 'bg-transparent', 'border']"
                      @change="handleVideoCall"
                    />  
                  </b-input-group>
                  <span
                    id="input-1-live-videoslots"
                    class="valdiation_error"
                  >
                    {{
                      veeErrors.first("videoslots")
                    }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="font-open-sans mb-2">
                <div class="font-weight-600">
                  Invitation for
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-select
                      id="invitation_type"
                      v-model="invitation_type"
                      v-validate="{ required: true }"
                      :state="validateState('invitation_type')"
                      :options="invitationType"
                      name="invitation_type"
                      :class="[style['field'], 'bg-transparent', 'border']"
                      @change="handleType"
                    />  
                  </b-input-group>
                  <span
                    id="input-1-live-invitation_type"
                    class="valdiation_error"
                  >
                    {{
                      veeErrors.first("invitation_type")
                    }}
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            pill
            class="px-5 text-uppercase float-right"
            @click="handleSubmit"
          >
            Invite
          </b-button>
          <b-button
            variant="default"
            pill
            class="mr-4 text-uppercase float-right"
            @click="handleClose"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import style from "../../styles/settings.module.scss";
import IconClose from '../../assets/svg-import/close.svg';
import LoadingSpinner from "../Common/LoadingSpinner";
import { getVideoCallList, getFollowerList, sendInvitation } from "../../api/meeting";
export default {
    name: "AddSlot",
    components: {IconClose,LoadingSpinner},
    props:{
        show:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            style,
            fans: "",
            videoslots: "",
            invitationType: [
                { value: "", text: "Select Type" },
                { value: "content", text: "Live Stream" },
                { value: "call", text: "Video Call" },
            ],
            fansList: [],
            options: [],
            invitation_type: "",
            loading: false,
            type: "",
        };
    },
    async created() {
        let followers = await getFollowerList();
        this.fansList = followers;
        let slotList = await getVideoCallList();
        this.options = slotList;
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleClose() {
            this.onReset();
            this.$emit("onClose");
        },
        handleType(value) {
            this.type = value;
        },
        async handleVideoCall(value) {
            this.fansList = [];
            let followers = await getFollowerList(value);
            this.fansList = followers;
        },
        onReset(){
            this.videoslots = "";
            this.fans = "";
            this.invitation_type = "";
        },
        async handleSubmit() {
            this.$validator.validateAll().then( async result => {
                if (!result) {
                    this.isLoading = false;
                    return;
                }
                this.loading = true;
                let invited = await sendInvitation(this.videoslots, this.fans);
                if (invited.status === true){
                    this.loading = false;
                    this.onReset();
                    this.$emit("onClose");
                }
            });
        },
    },
};
</script>