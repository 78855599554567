var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.style['post'], _vm.className]},[_c('div',{staticClass:"p-3"},[_c('post-author',{attrs:{"username":_vm.postUsername,"postBy":_vm.postBy,"profilePic":_vm.userId === _vm.postById ? _vm.profilePicImage : _vm.postProfilePic,"postTime":_vm.postTime,"postId":_vm.postId,"showDeletePopup":_vm.showDeletePopup,"showEditPopup":_vm.showEditPopup,"accType":_vm.accountType,"postData":_vm.postData,"userId":_vm.userId,"postIndex":_vm.postIndex,"exclusive":_vm.exclusive,"isExclusivePost":_vm.isExclusivePost,"profilePicImage":_vm.profilePicImage,"postById":_vm.postById}}),(_vm.postType == 3)?[_c('div',{class:['mt-3 ml-3 font-open-sans pre-formatted']},[_vm._v(" Book my exclusive private "+_vm._s(_vm.slotDetails.call_type == 1 ? "Video Call" : "Live Streaming")+". Here are the details : "),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_vm._v(" Date: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.moment(_vm.slotDetails.start_time).format("MMMM DD, YYYY")))])]),_c('b-col',{attrs:{"cols":"12"}},[_vm._v(" Time: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.moment(_vm.slotDetails.start_time).format("h:mm a"))+" - "+_vm._s(_vm.moment(_vm.slotDetails.end_time).format("h:mm a")))])]),(_vm.slotDetails.no_of_gab_allow)?_c('b-col',{attrs:{"cols":"12"}},[_vm._v(" Slots open: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.slotDetails.no_of_gab_allow-_vm.slotDetails.bookings_count))])]):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{class:[
                _vm.style['browse-fabs-button'],
                'text-uppercase vertical-align-center my-2' ],attrs:{"variant":"dark","pill":""},on:{"click":function($event){return _vm.bookSlot(_vm.slotDetails)}}},[_vm._v(" Book Now ")])],1)],1)],1)]:[(_vm.exclusive === 0)?_c('div',{staticClass:"mt-3"},[_c('b-skeleton',{attrs:{"animation":"wave","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"70%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"55%"}})],1):_vm._e(),(_vm.exclusive === 1)?_c('div',{class:['mt-3 ml-3 font-open-sans pre-formatted']},[_vm._v(" "+_vm._s(_vm.caption1 !== "" ? _vm.caption1 : _vm.postCaption)+" ")]):_vm._e(),(_vm.showCaption)?_c('div',{class:['ml-3 font-open-sans pre-formatted']},[_vm._v(" "+_vm._s(_vm.caption2)+" ")]):_vm._e(),(_vm.exclusive === 1)?_c('div',{staticClass:"text-right"},[(_vm.postCaption && _vm.postCaption.length > 320)?_c('b-button',{staticClass:"bg-transparent font-weight-bold shadow-none",attrs:{"variant":"default"},on:{"click":function () { return (_vm.showCaption = !_vm.showCaption); }}},[_vm._v(" Read "+_vm._s(_vm.showCaption ? "less" : "more")+"> ")]):_vm._e()],1):_vm._e()]],2),(_vm.exclusive === 0 && _vm.postType != 3)?[_c('div',{class:[_vm.style['comment-wrapper']]},[_c('div',{staticClass:"d-flex p-3"},[_c('div',{staticClass:"flex-grow-1 d-flex mr-2 mr-sm-3"},[_c('b-button',{ref:"button",class:[
              _vm.style['exclusive-content-btn'],
              'btn-pop text-uppercase font-weight-bold' ],attrs:{"variant":"dark","squared":"","block":""},on:{"click":function($event){return _vm.handleRedirect(_vm.postBy, _vm.postProfilePic, _vm.postById, _vm.postUsername)}}},[_vm._v(" Get Exclusive Content ")])],1),_c('div',{class:[
            'flex-shrink-0 d-flex align-items-center justify-content-end',
            _vm.style['love-count-wrapper'] ]},[(_vm.postLoves > 0)?_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.postLoves)+" loves")]):_vm._e()])])])]:_vm._e(),(_vm.exclusive === 1)?[_c('div',[(_vm.includeVideo === false && _vm.postPic !== '')?_c('b-img',{staticClass:"post-caption-image",attrs:{"id":("postImage" + _vm.postId),"fluid":"","src":_vm.postPic},on:{"error":function($event){return _vm.handleError(("postImage" + _vm.postId))}}}):_vm._e(),(_vm.includeVideo)?_c('div',{staticClass:"video-wrapper"},[(_vm.includeVideo && !_vm.videoError)?_c('video',{staticClass:"video-file",staticStyle:{"width":"100%"},attrs:{"id":("video-file-" + _vm.postId)},on:{"ended":function($event){return _vm.handleVideoEnd(_vm.postId)}}},[_c('source',{attrs:{"src":_vm.postPic,"type":"video/mp4"},on:{"error":function($event){return _vm.handleVideoError(("video-file-" + _vm.postId))}}})]):_vm._e(),(_vm.includeVideo && _vm.play)?_c('div',{staticClass:"play",on:{"click":function($event){return _vm.handlePlayPause(_vm.postId)}}}):_vm._e(),(_vm.includeVideo && _vm.play === false && !_vm.videoError)?_c('div',{staticClass:"pause",on:{"click":function($event){return _vm.handlePlayPause(_vm.postId)}}}):(_vm.videoError)?_c('b-img',{staticClass:"post-caption-image",attrs:{"id":("postImage" + _vm.postId),"fluid":"","src":_vm.postPic},on:{"error":function($event){return _vm.handleVideoError(("postImage" + _vm.postId))}}}):_vm._e()],1):_vm._e()],1),(_vm.postType != 3)?_c('div',{class:[_vm.style['comment-wrapper']]},[(_vm.postComments.length)?_c('div',{staticClass:"p-3 border-bottom"},[_vm._l((_vm.postComments),function(comment,cIndex){return _c('div',{key:comment.id,class:[
            'd-flex',
            {
              'mb-3': cIndex < _vm.postComments.length - 1,
            } ]},[_c('img',{class:[
              'rounded-circle mr-2 flex-shrink-0',
              _vm.style['profile-pic'] ],attrs:{"src":_vm.userId == comment.author.id
                ? _vm.profilePicImage
                : comment.author.profile},on:{"error":_vm.setPlaceholderProfileImg}}),_c('div',{class:[_vm.style['content'], 'py-2 px-3 flex-grow-1']},[_c('div',[_c('div',[_c('strong',{staticClass:"mr-2 text-uppercase"},[_vm._v(_vm._s(comment.author.username))]),_c('span',{staticClass:"font-open-sans"},[_vm._v(_vm._s(comment.comment))])]),_c('span',{staticClass:"font-open-sans"},[_vm._v(_vm._s(_vm.fromNowTime(new Date(comment.commentTime))))])]),_c('b-badge',{class:[
                _vm.style['comment-love'],
                'py-1',
                'border',
                { 'border-primary': comment.loveByUser } ],attrs:{"pill":"","variant":comment.loveByUser ? 'primary' : 'white',"role":"button"},on:{"click":function($event){return _vm.toggleCommentLove(comment.id, _vm.postId)}}},[_vm._v(" "+_vm._s(comment.loves > 0 ? comment.loves : "")+" "),_c('icon-heart',{class:[{ 'stroke-primary': comment.loveByUser }],attrs:{"width":"15","height":"15","fill":"#fff"}})],1)],1)])}),(_vm.showMore)?_vm._l((_vm.postComments2),function(comment,cIndex){return _c('div',{key:comment.id,class:[
              'd-flex',
              'mt-3',
              {
                'mb-3': cIndex < _vm.postComments2.length - 1,
              } ]},[_c('img',{class:[
                'rounded-circle mr-2 flex-shrink-0',
                _vm.style['profile-pic'] ],attrs:{"src":_vm.userId == comment.author.id
                  ? _vm.profilePicImage
                  : comment.author.profile},on:{"error":_vm.setPlaceholderProfileImg}}),_c('div',{class:[_vm.style['content'], 'py-2 px-3 flex-grow-1']},[_c('div',[_c('div',[_c('strong',{staticClass:"mr-2 text-uppercase"},[_vm._v(_vm._s(comment.author.username))]),_c('span',{staticClass:"font-open-sans"},[_vm._v(_vm._s(comment.comment))])]),_c('span',{staticClass:"font-open-sans"},[_vm._v(_vm._s(_vm.fromNowTime(new Date(comment.commentTime))))])]),_c('b-badge',{class:[
                  _vm.style['comment-love'],
                  'py-1',
                  'border',
                  { 'border-primary': comment.loveByUser } ],attrs:{"pill":"","variant":comment.loveByUser ? 'primary' : 'white',"role":"button"},on:{"click":function($event){return _vm.toggleCommentLove(comment.id, _vm.postId)}}},[_vm._v(" "+_vm._s(comment.loves > 0 ? comment.loves : "")+" "),_c('icon-heart',{class:[{ 'stroke-primary': comment.loveByUser }],attrs:{"width":"15","height":"15","fill":"#fff"}})],1)],1)])}):_vm._e(),(_vm.comments.length > 3)?_c('div',{staticClass:"text-right mt-3 font-open-sans"},[_c('a',{staticClass:"bg-transparent",on:{"click":function($event){_vm.showMore ? (_vm.showMore = false) : (_vm.showMore = true)}}},[_vm._v(_vm._s(_vm.showMore ? "Show less" : "Show more"))])]):_vm._e()],2):_vm._e(),_c('div',{staticClass:"d-flex p-3"},[_c('div',{staticClass:"flex-grow-1 d-flex mr-2 mr-sm-3"},[_c('b-img',{class:[
              'rounded-circle mr-2 author-profile-pic',
              _vm.style['profile-pic'] ],attrs:{"id":"author-profile-pic","fluid":"","src":_vm.profilePicImage,"alt":_vm.username},on:{"error":_vm.setPlaceholderProfileImg}}),_c('b-form-group',{staticClass:"flex-grow-1 mb-0",attrs:{"label-for":"user_comment"}},[_c('b-form-input',{staticClass:"py-2 shadow-none",attrs:{"id":("user_comment_" + _vm.postId),"name":("user_comment_" + _vm.postId),"placeholder":"Leave a comment"},on:{"change":function (e) { return _vm.addPostComment(_vm.postId, e); }},model:{value:(_vm.userCommentInput),callback:function ($$v) {_vm.userCommentInput=$$v},expression:"userCommentInput"}})],1)],1),_c('div',{class:[
            'flex-shrink-0 d-flex align-items-center justify-content-end',
            _vm.style['love-count-wrapper'] ]},[(_vm.postLoves > 0)?_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.postLoves)+" loves")]):_vm._e(),_c('b-button',{staticClass:"p-0 shadow-none",attrs:{"variant":"default"},on:{"click":function($event){return _vm.toggleLove(_vm.postId)}}},[_c('icon-heart',{class:[{ 'fill-primary stroke-primary': _vm.postUserLove }],attrs:{"width":"30","height":"30"}})],1)],1)])]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }