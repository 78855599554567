<template>
  <b-container :class="[style['post'], style['add-post-wrapper'], 'border']">
    <LoadingSpinner v-if="loading" />
    <Invitations
      :show="show"
      @onClose="handleClose"
    />
    <b-row>
      <b-col
        cols="4"
        :class="[
          style['post-text'],
          'py-2',
          currTab === '1' ? 'add-post-active' : '',
        ]"
        style="cursor: pointer"
        @click="handleTabs('1')"
      >
        Post
      </b-col>
      <b-col
        cols="8"
        :class="[
          style['invitation-text'],
          'py-2',
          currTab === '2' ? 'add-post-active' : '',
        ]"
        style="cursor: pointer"
        @click="handleTabs('2')"
      >
        Invitation
      </b-col>
    </b-row>
    <section v-if="currTab === '1'">
      <b-input-group class="font-open-sans mb-3">
        <div
          v-if="post_image !== ''"
          :class="['mt-3', style['post-image-wrapper']]"
        >
          <img
            v-if="isVideo === false"
            :src="post_image"
            alt="post1"
            :class="[style['post-image'], 'my-2', 'mx-1']"
          >
          <video
            v-else
            :class="[style['post-image'], 'my-2', 'mx-1']"
          >
            <source :src="post_image">
          </video>
          <button
            :class="style['top-right']"
            @click="removeImage"
          >
            <img
              :src="closeIcon"
              style="height: 30px; width: 30px"
            >
          </button>
        </div>
        <b-form-textarea
          id="message"
          v-model="message"
          name="message"
          placeholder="How is your day?"
          :class="[style['post-text-area'], 'no-resize', 'border-0 ml-3']"
          rows="4"
          @input="handleInput"
          @click="hideEmoji"
        />
      </b-input-group>
      <b-row :class="style['add-post-footer']">
        <b-col class="pt-2">
          <input
            id="upload-photo-video"
            type="file"
            style="display: none"
            name="upload"
            accept=".jpg, .jpeg, .png, .mp4"
            @change="handlePostImage"
          ><label for="upload-photo-video">+Upload photo/video</label>

          <div class="emoji-invoker">
            <svg
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
              @click="ShowEmoji"
            >
              <path
                d="M0 0h24v24H0z"
                fill="none"
              />
              <path
                d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
              />
            </svg>

            <picker
              v-if="EmojiShow"
              @select="addEmoji"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          md="auto"
          :class="[style['select-wrapper'], 'py-2']"
        >
          <select
            id="is_exclusive_post"
            v-model="is_exclusive_post"
            name="is_exclusive_post"
            :class="[style['select-post'], 'mr-4']"
          >
            <option value="0">
              Public Post
            </option>
            <option value="1">
              Exclusive Post
            </option>
          </select>
          <svg
            :class="[style['select-svg'], 'mx-2']"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="caret-down"
            class="svg-inline--fa fa-caret-down fa-w-10"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
            />
          </svg>
        </b-col>
        <b-col
          col
          lg="3"
          :class="[style['post-button'], 'text-center px-0']"
        >
          <b-overlay
            :show="isLoading"
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="font-weight-bold bg-transparent h-100"
          >
            <b-button
              class="bg-transparent border-0 shadow-none w-100"
              variant="dark"
              :disabled="isDisabled"
              style="color: white"
              @click="addNewPost"
            >
              POST
            </b-button>
          </b-overlay>
        </b-col>
      </b-row>
    </section>
    <section v-else>
      <b-row class="mb-1 mt-3">
        <b-col cols="4">
          <div class="font-open-sans mb-2">
            <div class="font-weight-600">
              Invitation for
            </div>
            <div class="bank-search-input">
              <b-input-group class="mt-1">
                <!-- <b-form-select
                  id="invitation_type"
                  v-model="invitation_type"
                  v-validate="{ required: true }"
                  :state="validateState('invitation_type')"
                  :options="invitationType"
                  name="Invitation Type"
                  :class="[style['field'], 'bg-transparent', 'border']"
                  @change="handleType"
                />   -->
                <Select2
                  id="invitation_type"
                  v-model="invitation_type"
                  v-validate="{ required: true }"
                  :state="validateState('invitation_type')"
                  name="Invitation Type"
                  :class="[
                    'bg-transparent',
                    'border invite-select',
                    style['field'],
                  ]"
                  :options="invitationType"
                  placeholder="Select Type"
                  aria-describedby="input-1-live-fans"
                  data-vv-as="Invitation Type"
                  style="width: 100%"
                  @change="handleType"
                />
              </b-input-group>
              <span
                id="input-1-live-invitation_type"
                class="valdiation_error"
              >
                {{ veeErrors.first("Invitation Type") }}
              </span>
            </div>
          </div>
        </b-col>
        <b-col cols="8">
          <div class="font-open-sans mb-2">
            <div class="font-weight-600">
              Slots
            </div>
            <div class="bank-search-input">
              <b-input-group class="mt-1">
                <!-- <b-form-select
                  id="videoslots"
                  v-model="videoslots"
                  v-validate="{ required: true }"
                  :state="validateState('videoslots')"
                  :options="options"
                  name="videoslots"
                  :class="[style['field'], 'bg-transparent', 'border']"
                  @change="handleVideoSlot"
                />   -->
                <Select2
                  id="videoslots"
                  v-model="videoslots"
                  v-validate="{ required: true }"
                  :state="validateState('videoslots')"
                  name="videoslots"
                  :class="[
                    'bg-transparent',
                    'border invite-select',
                    style['field'],
                  ]"
                  :options="options"
                  placeholder="Select Slot"
                  aria-describedby="input-1-live-fans"
                  data-vv-as="slot"
                  style="width: 100%"
                  @change="handleVideoSlot"
                />
              </b-input-group>
              <span
                id="input-1-live-videoslots"
                class="valdiation_error"
              >
                {{ veeErrors.first("videoslots") }}
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <div class="font-open-sans mb-2">
            <div class="font-weight-600">
              Fans
            </div>
            <div class="bank-search-input">
              <b-input-group class="mt-1">
                <Select2
                  id="fans"
                  v-model="fans"
                  v-validate="{ required: true }"
                  :state="validateState('fans')"
                  :settings="{ multiple: true }"
                  name="fans"
                  :class="['bg-transparent', 'border', style['field']]"
                  :options="fansList"
                  placeholder="Select Fans"
                  aria-describedby="input-1-live-fans"
                  data-vv-as="Fans"
                  style="width: 100%"
                />
                <template #append>
                  <svg
                    :class="[style['select-svg'], 'mx-2']"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-down"
                    class="svg-inline--fa fa-caret-down fa-w-10"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                    />
                  </svg>
                </template>
              </b-input-group>
              <span
                id="input-1-live-fans"
                class="valdiation_error"
              >
                {{ veeErrors.first("fans") }}
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row
        :class="[style['add-post-footer'], 'justify-content-end']"
        style="margin-top: 1%"
      >
        <b-col
          lg="3"
          :class="[style['post-button'], 'text-center px-0']"
        >
          <b-overlay
            :show="isLoading"
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="font-weight-bold bg-transparent h-100"
          >
            <b-button
              class="text-uppercase bg-transparent border-0 shadow-none w-100"
              variant="dark"
              style="color: white"
              @click="handleSubmit"
            >
              Invite
            </b-button>
          </b-overlay>
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>

<script>
import style from "../../styles/feed.module.scss";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import { APIUrlList } from "../../js/api_url";
import { uploadToBucket, _sendResponse } from "../../js/common-function";
import {
    getVideoCallList,
    getFollowerList,
    sendInvitation,
} from "../../api/meeting";
import closeIcon from "../../assets/images/imgpsh_fullsize_anim.png";
import { Picker } from "emoji-mart-vue";
import Invitations from "./Invitations";
import LoadingSpinner from "../Common/LoadingSpinner";

export default {
    name: "AddPost",
    components: {
    // EmojiPicker,
        Picker,
        Invitations,
        LoadingSpinner,
    },
    directives: {
        focus: {
            inserted(el) {
                el.focus();
            },
        },
    },
    props: {
        updatePosts: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            input: "",
            search: "",
            style,
            closeIcon,
            post_image: "",
            message: "",
            post_media: "",
            is_exclusive_post: 0,
            media_url: "",
            post_type: 1,
            post_media_type: "",
            isLoading: false,
            isVideo: false,
            isDisabled: true,
            EmojiShow: false,
            show: false,
            currTab: "1",
            fans: "",
            videoslots: "",
            invitationType: [
                { id: "", text: "Select Type" },
                { id: "1", text: "Video Call" },
                { id: "2", text: "Live Stream" },
            ],
            fansList: [],
            options: [],
            invitation_type: "",
            type: "",
            loading: false,
        };
    },
    async created() {
        let followers = await getFollowerList();
        this.fansList = followers;
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        ShowEmoji() {
            if (this.EmojiShow === false) {
                this.EmojiShow = true;
            } else {
                this.EmojiShow = false;
            }
        },
        hideEmoji() {
            this.EmojiShow = false;
        },
        append(emoji) {
            this.message += emoji;
            this.isDisabled = false;
        },
        addEmoji(emoji) {
            this.message += emoji.native;
            this.isDisabled = false;
            this.EmojiShow = false;
        },
        handlePostImage(e) {
            this.isDisabled = false;
            this.isVideo = e.target.files[0].type.includes("video") ? true : false;
            this.post_image = URL.createObjectURL(e.target.files[0]);
            this.post_media = e.target.files[0];
            this.post_type = 2;
            this.post_media_type = this.isVideo ? "video" : "image";
        },
        removeImage() {
            this.post_image = "";
            this.post_media = "";
            this.post_type = 1;
            this.message === ""
                ? (this.isDisabled = true)
                : (this.isDisabled = false);
        },
        openInvitationPopup() {
            this.show = true;
        },
        handleClose() {
            this.show = false;
        },
        async handleVideoSlot(value) {
            this.loading = true;
            let list = await getFollowerList(value);
            if (list[1] === undefined) {
                this.loading = false;
                this.fansList = [];
            } else {
                this.loading = false;
                this.fansList = list;
            }
        },
        async addNewPost() {
            this.isLoading = true;
            // if (this.message !== "" || this.post_image !== ""){
            if (this.post_type === 1) {
                if (this.message.trim() === "") {
                    _sendResponse(
                        "error",
                        "Invalid Post content: Post content should not be empty."
                    );
                    this.isLoading = false;
                    return false;
                }
            }
            if (this.post_type === 2) {
                this.media_url = await uploadToBucket(
                    this.post_media,
                    "uploads/posts/"
                );
            }

            var formData = {
                message: this.message,
                is_exclusive_post: this.is_exclusive_post,
                media_url: this.media_url,
                post_type: this.post_type,
                media_type: this.post_media_type,
            };
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .post(API_BASE_URL + APIUrlList.ADDPOST, formData)
                .then((response) => {
                    this.isLoading = false;
                    this.updatePosts(response.data.data, "add");
                    this.message = "";
                    this.post_image = "";
                    this.post_media = "";
                    this.post_type = 1;
                    this.isDisabled = true;
                })
                .catch((error) => {
                    console.error(error);
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                });
        },
        handleInput(e) {
            this.post_image === ""
                ? (this.isDisabled = true)
                : (this.isDisabled = false);
            e === "" ? (this.isDisabled = true) : (this.isDisabled = false);
        },
        handleTabs(value) {
            this.currTab = value;
            this.onReset();
        },
        async handleType(value) {
            this.type = value;
            if (value !== "") {
                this.loading = true;
                let slotList = await getVideoCallList(value);
                if (slotList && slotList.length > 0) {
                    this.loading = false;
                    this.options = slotList;
                } else {
                    this.loading = false;
                }
            }
        },
        onReset() {
            this.videoslots = "";
            this.fans = "";
            this.invitation_type = "";
        },
        async handleSubmit() {
            this.$validator.validateAll().then(async (result) => {
                this.loading = true;
                if (!result) {
                    this.isLoading = false;
                    this.loading = false;
                    return;
                }
                let invited;
                let fanIdsToPass = [];
                if (this.fans.includes("all")) {
                    this.fansList.map((gab) => {
                        let idToPush = gab.id;
                        fanIdsToPass.push(idToPush);
                    });
                    fanIdsToPass.shift();
                    invited = await sendInvitation(this.videoslots, fanIdsToPass);
                } else {
                    invited = await sendInvitation(this.videoslots, this.fans);
                }
                this.loading = true;
                if (invited.status === true) {
                    this.loading = false;
                    this.onReset();
                    this.$validator.reset();
                    this.$emit("onClose");
                }
            });
        },
    },
};
</script>
