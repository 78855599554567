<template>
  <b-card class="mb-4 text-center px-5 py-5 shadow">
    <h5 class="font-weight-bolder font-open-sans">
      No new events yet.
    </h5>
    <div
      v-if="account_type === '1' && fabId===''"
      class="pt-4"
    >
      <b-button
        variant="primary"
        pill
        :class="[style['browse-fabs-button'], 'text-uppercase']"
        to="/celebsearch"
      >
        Browse popz
      </b-button>
    </div>
  </b-card>
</template>
<script>
import style from "../../styles/feed.module.scss";
export default {
    data() {
        return {
            style,
            account_type:localStorage.getItem('account-type'),
            fabId: localStorage.getItem('fabId'),
        };
    }
};
</script>