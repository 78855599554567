<template>
  <b-card :class="[className, style['shop-card'], 'mb-4 text-center']">
    <b-img
      :id="`product_${productId}`"
      style="height:170px"
      :src="productImage !== '' ? productImage : placeholderImage"
      :alt="productTitle"
      fluid
      @error="handleError(`product_${productId}`)"
    />
    <p class="text-uppercase text-left mt-1 text-truncate mb-0">
      {{ productTitle }}
    </p>
    <p class="text-left mb-0">
      ${{ parseFloat(productPrice).toFixed(2) }}
    </p>
    <div class="text-right">
      <b-button
        v-if="account_type === '2' && userId == productUserId"
        variant="primary"
        pill
        :class="[style['buy-now-btn'],'text-uppercase']"
        @click="handlePurchase(productId)"
      >
        View
      </b-button>
      <b-button
        v-else
        variant="primary"
        pill
        :class="[style['buy-now-btn'],'text-uppercase']"
        @click="handlePurchase(productId)"
      >
        Buy Now
      </b-button>
    </div>
  </b-card>
</template>

<script>
import style from "../../styles/feed.module.scss";
import placeholderImage from '../../assets/images/img-prod-placeholder.png';
export default {
    props: {
        className: {
            type: [String, Array],
            default: "",
        },
        productImage: {
            required: true,
            type: String,
        },
        productTitle: {
            required: true,
            type: String,
        },
        productPrice: {
            required: true,
            type: [String, Number]
        },
        type:{
            type:String,
            default: ""
        },
        productId:{
            type: [String, Number],
            default:""
        },
        productUserId:{
            type: [String, Number],
            default:""
        }
    },
    data() {
        return {
            style,
            account_type:localStorage.getItem('account-type'),
            userId: localStorage.getItem('userId'),
            placeholderImage,
        };
    },
    methods:{
        async handlePurchase(id){
            this.$router.push(`/product/${id}`);
        },
        handleError(data){
            document.getElementById(data).src = placeholderImage;
        }
    }
};
</script>
