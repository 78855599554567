<template>
  <div
    id="modal-sm___BV_modal_content_"
    :class="[style['modal-wrapper'],'modal-content bg-dark btn-pop']"
  >
    <header
      id="modal-sm___BV_modal_header_"
      :class="[style['reminder-title'],'modal-header text-uppercase']"
    >
      <h5
        id="modal-sm___BV_modal_title_"
        class="modal-title"
      >
        Upcoming VideoCall
      </h5>
    </header>
    <div
      id="modal-sm___BV_modal_body_"
      :class="[style['reminder-content'],'modal-body']"
    >
      Your video call with Melissa Miller should begin in 3:23
    </div>
    <footer
      id="modal-sm___BV_modal_footer_"
      :class="[style['reminder-footer'],'modal-footer']"
    >
      <b-button
        variant="primary"
        pill
        :class="[style['buy-now-btn'],'text-uppercase']"
      >
        Let's Start! 
      </b-button>
    </footer>
  </div>
</template>

<script>
import style from '../../styles/feed.module.scss';
export default {
    name: "Reminder",
    data(){
        return {
            style,
        };
    },
};
</script>
