<template>
  <section>
    <div class="text-right">
      <b-button
        v-if="account_type === '1' && fabId != ''"
        ref="button"
        variant="dark"
        :class="[
          style['follow-btn'],
          'btn-pop text-uppercase font-weight-bold',
        ]"
        squared
        block
        @click="followFab"
      >
        {{ isfollowing == null || isfollowing == 0 ? "FOLLOW" : "UNFOLLOW" }}
      </b-button>
    </div>
    <div
      v-if="fabId"
      class="d-flex flex-column text-center"
    >
      <p class="pt-2 mr-3">
        {{ fullName }}
      </p>
      <p class="mr-3">
        {{ about_you }}
      </p>
      <p class="mr-3">
        {{ displayTotalPosts }} posts
      </p>
      <p class="mr-3 d-none">
        {{ exclusivePost }} exclusive posts
      </p>
      <p
        :class="[style['cursor-pointer'], 'mr-3']"
        @click="
          () =>
            followersAccountType !== ''
              ? $router.push(`/followers-following/${followersAccountType}`)
              : $router.push('/followers-following')
        "
      >
        {{ followers }} followers
      </p>
      <p
        :class="[style['cursor-pointer'], 'mr-3']"
        @click="
          () =>
            followersAccountType !== ''
              ? $router.push(`/followers-following/${followersAccountType}`)
              : $router.push('/followers-following')
        "
      >
        {{ following }} following
      </p>
      <p>
        <b-button
          v-if="account_type === '1'"
          ref="button"
          variant="dark"
          :class="[
            style['exclusive-content-btn'],
            'btn-pop text-uppercase font-weight-bold',
          ]"
          squared
          block
          @click="() => $router.push('/booking/content')"
        >
          Get Exclusive Content
        </b-button>
      </p>
    </div>
    <h6
      :class="[style['upcoming-events'], 'text-uppercase']"
      @click="handleUpcomingEvents"
    >
      Upcoming events
      <svg
        :class="[style['select-svg'], 'mx-3']"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="caret-down"
        class="svg-inline--fa fa-caret-down fa-w-10"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="currentColor"
          d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
        />
      </svg>
    </h6>
    <ul
      v-if="show"
      :class="[style['event-list'], 'list-unstyled']"
    >
      <li
        v-for="(upcomingEvent, aIndex) in fabUpcomingList"
        :key="`upcomingevent${aIndex}`"
        :class="aIndex == 0 ? [style['active'], 'pl-3 mb-3'] : 'pl-3 mb-3'"
      >
        <strong class="small"> {{ moment(aIndex).format("MM/DD") }} </strong>

        <div v-if="account_type === '1' && fabId != ''">
          <p
            v-for="bookslot of upcomingEvent"
            :key="bookslot.id"
            class="mb-0 font-open-sans"
          >
            {{ bookslot.start_time }}
          </p>
        </div>
        <div v-else>
          <p
            v-for="bookslot of upcomingEvent"
            :key="bookslot.id"
            class="mb-0 font-open-sans"
          >
            <span v-if="bookslot.call_type === 1">
              <span class="font-weight-bold small">
                <!-- {{ bookslot.start_time }}  -->
              </span>
              {{ bookslot.name }} video call
            </span>
            <span v-else>
              <!-- {{ bookslot.start_time }}  -->
              {{ bookslot.name }} live streaming
            </span>
          </p>
        </div>
      </li>
      <button
        v-if="
          account_type === '1' && fabId != '' && fabUpcomingList != '' && show
        "
        class="px-0 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none"
        @click="showSlotPopup()"
      >
        Show More
      </button>
    </ul>
    <!-- <h6
      v-if="account_type === '1' && fab_invites.length > 0 || Object.keys(fab_invites).length > 0"
      class="text-uppercase"
    >
      Invitations
    </h6> -->
    <h6
      v-if="
        (account_type === '1' && fab_invites.length > 0) ||
          Object.keys(fab_invites).length > 0
      "
      :class="[style['upcoming-events'], 'text-uppercase']"
      @click="handleInvitations"
    >
      Invitations
      <svg
        :class="[style['select-svg'], 'mx-3']"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="caret-down"
        class="svg-inline--fa fa-caret-down fa-w-10"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="currentColor"
          d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
        />
      </svg>
    </h6>
    <ul
      v-if="
        (fab_invites.length > 0 || Object.keys(fab_invites).length > 0) &&
          showInvites
      "
      :class="[style['event-list'], 'list-unstyled']"
    >
      <li
        v-for="(invite, index) in fab_invites"
        :key="index"
        :class="index == 0 ? [style['active'], 'pl-3 mb-3'] : 'pl-3 mb-3'"
      >
        <strong class="small">
          {{ moment(invite.date).format("MM/DD") }}
        </strong>
        <div>
          <p class="mb-0 font-open-sans">
            Invitation from {{ invite.fab_name }}
          </p>
        </div>
      </li>
    </ul>
    <button
      v-if="totalInvitations.length > 0 && showInvites"
      class="px-0 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none"
      @click="$emit('handleInvitePopUp')"
    >
      Show More
    </button>
    <router-link
      v-if="products.length > 0"
      :to="`/products/${encodeFabId}`"
      class="px-2 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none"
    >
      See the store
    </router-link>
    <AddPost
      v-if="account_type === '2'"
      class="mb-4"
      :updatePosts="updatePosts"
    />
    <b-row class="mx-0">
      <b-col cols="12">
        <live-stream :liveStreams="liveStreams" />
      </b-col>
    </b-row>
    <template v-if="posts.length">
      <div
        v-for="(post, pIndex) in firstPost"
        :key="`post${post.id}`"
        class="mt-3"
      >
        <post
          :className="[
            {
              'mb-4': pIndex < posts.length - 1,
            },
          ]"
          :profilePic="profile"
          :loveByUser="post.loveByUser"
          :username="username"
          :postUsername="post.username"
          :postProfilePic="post.profile"
          :postTime="new Date(post.postTime)"
          :postCaption="post.postCaption"
          :postPic="post.postPic"
          :loves="post.loves"
          :comments="post.comments"
          :exclusive="post.exclusive"
          :postId="post.id"
          :getPostList="getPostList"
          :postBy="post.postBy"
          :userId="userId"
          :postIndex="pIndex"
          :postData="firstPost"
          :slotDetails="post.slotDetails"
          :postType="post.post_type"
          :updatePosts="updatePosts"
        />
      </div>
      <shop-card-sm
        v-for="(product, pIndex) in firstProduct"
        :key="`product1_${pIndex}`"
        :productImage="product.image"
        :productTitle="product.title"
        :productPrice="product.price"
        :productId="product.id"
        :className="['d-block d-sm-block d-md-none mt-4']"
      />
    </template>
    <template v-if="posts.length">
      <div
        v-for="(post, pIndex) in secondPost"
        :key="`post${post.id}`"
      >
        <post
          :className="[
            {
              'mb-4': pIndex < posts.length - 1,
            },
          ]"
          :profilePic="profile"
          :loveByUser="post.loveByUser"
          :username="username"
          :postUsername="post.username"
          :postProfilePic="post.profile"
          :postTime="new Date(post.postTime)"
          :postCaption="post.postCaption"
          :postPic="post.postPic"
          :loves="post.loves"
          :comments="post.comments"
          :exclusive="post.exclusive"
          :postId="post.id"
          :getPostList="getPostList"
          :postBy="post.postBy"
          :userId="userId"
          :postIndex="pIndex"
          :postData="secondPost"
          :updatePosts="updatePosts"
        />
      </div>
      <shop-card-sm
        v-for="(product, pIndex) in secondProduct"
        :key="`product2_${pIndex}`"
        :productImage="product.image"
        :productTitle="product.title"
        :productPrice="product.price"
        :className="['d-block d-sm-block d-md-none mt-4']"
      />
    </template>
    <template v-if="posts.length">
      <div
        v-for="(post, pIndex) in remainingPosts"
        :key="`post${post.id}`"
      >
        <post
          :className="[
            {
              'mb-4': pIndex < posts.length - 1,
            },
          ]"
          :profilePic="profile"
          :loveByUser="post.loveByUser"
          :username="username"
          :postUsername="post.username"
          :postProfilePic="post.profile"
          :postTime="new Date(post.postTime)"
          :postCaption="post.postCaption"
          :postPic="post.postPic"
          :loves="post.loves"
          :comments="post.comments"
          :exclusive="post.exclusive"
          :postId="post.id"
          :getPostList="getPostList"
          :showDeletePopup="showDeletePopup"
          :showEditPopup="showEditPopup"
          :postBy="post.postBy"
          :userId="userId"
          :postIndex="pIndex"
          :postData="remainingPosts"
          :updatePosts="updatePosts"
        />
      </div>
    </template>
  </section>
</template>

<script>
import Post from "../../components/Feed/Post.vue";
import AddPost from "../../components/Feed/AddPost.vue";
import ShopCardSm from "../../components/Feed/ShopCardSm.vue";
import style from "../../styles/feed.module.scss";
import { dateFormat, timeFormat } from "../../js/common-function";
import LiveStream from "../../components/Feed/LiveStream.vue";

export default {
    components: { Post, AddPost, ShopCardSm, LiveStream },
    props: {
        totalPublicPost: {
            type: Number,
            required: true,
        },
        showDeletePopup: {
            type: Function,
            default: () => {},
        },
        showEditPopup: {
            type: Function,
            default: () => {},
        },
        totalExclusivePost: {
            type: Number,
            required: true,
        },
        totalFollowers: {
            type: [Number, String],
            required: true,
        },
        totalFollowing: {
            type: Number,
            required: true,
        },
        aboutFAB: {
            type: String,
            default: "",
        },
        postsData: {
            type: Array,
            required: true,
            default: () => [],
        },
        productsData: {
            type: Array,
            required: true,
        },
        fabUpcomingListData: {
            type: [Array, Object],
            required: true,
        },
        profilePic: {
            type: String,
            required: true,
        },
        updatePosts: {
            type: Function,
            default: () => {},
        },
        getPostList: {
            type: Function,
            default: () => {},
        },
        fabId: {
            type: Number,
            default: localStorage.getItem("fabId"),
        },
        userId: {
            type: [Number, String],
            default: "",
        },
        showSlotPopup: {
            type: Function,
            default: () => {},
        },
        invites: {
            type: [Object, Array],
            default: () => [],
        },
        totalInvites: {
            type: [Object, Array],
            default: () => [],
        },
        isfollowing: {
            type: Number,
            default: 0,
        },
        fullName: {
            type: String,
            default: "",
        },
        liveStreams: {
            type: Array,
            default: () => [],
        },
        displayTotalPosts: {
            type: [Number, String],
            default: "",
        },
    },
    data() {
        return {
            style,
            username: localStorage.getItem("username"),
            profile: this.profilePic,
            account_type: localStorage.getItem("account-type"),
            firstPost: [],
            firstProduct: [],
            secondPost: [],
            secondProduct: [],
            remainingPosts: [],
            show: true,
            posts: this.postsData,
            products: this.productsData,
            fabUpcomingList: this.fabUpcomingListData,
            eventDateFormat: dateFormat,
            eventTimeFormat: timeFormat,
            postIndex: 0,
            productIndex: 0,
            encodeFabId: window.btoa(localStorage.getItem("fabId")),
            publicPost: this.totalPublicPost,
            exclusivePost: this.totalExclusivePost,
            followers: this.totalFollowers,
            following: this.totalFollowing,
            about_you: this.aboutFAB,
            fab_invites: this.invites,
            totalInvitations: this.totalInvites,
            showInvites: false,
            followersAccountType: "",
        };
    },
    watch: {
        postsData: function(newVal) {
            if (Object.keys(this.posts) !== Object.keys(newVal)) {
                this.posts = newVal;
                this.firstPost = this.posts.slice(0, 2);
                this.secondPost = this.posts.slice(2, 4);
                this.remainingPosts = this.posts.slice(4);
            }
            this.encodeFabId = window.btoa(localStorage.getItem("fabId"));
        },
        profilePic: function(newVal) {
            this.profile = newVal;
        },
        adsData: function(newVal) {
            this.advertisements = newVal;
        },
        totalPublicPost: function(newVal) {
            this.publicPost = newVal;
        },
        totalExclusivePost: function(newVal) {
            this.exclusivePost = newVal;
        },
        totalFollowers: function(newVal) {
            this.followers = newVal;
        },
        totalFollowing: function(newVal) {
            this.following = newVal;
        },
        aboutFAB: function(newVal) {
            this.about_you = newVal;
        },
        productsData: function(newVal) {
            this.products = newVal;
            this.firstProduct = this.products.slice(0, 1);
            this.secondProduct = this.products.slice(1, 2);
        },
        fabUpcomingListData: function(newVal) {
            this.fabUpcomingList = newVal;
        },
        invites: function(newVal) {
            this.fab_invites = newVal;
        },
        totalInvites: function(newVal) {
            this.totalInvitations = newVal;
        },
    },
    created: function() {
        if (
            this.account_type === "1" ||
      (this.account_type === "2" &&
        this.$route.params.username &&
        this.$route.params.username != this.username)
        ) {
            this.followersAccountType = this.$route.params.username;
        }
    },
    methods: {
        handleUpcomingEvents() {
            this.show = !this.show;
        },
        handleInvitations() {
            this.showInvites = !this.showInvites;
        },
        followFab() {
            this.$emit("changeFollowStatus");
        },
    },
};
</script>
