<template>
  <div :class="['d-block mx-0 w-100', style['feed-view'], [fabId != null && fabId != 0 ? 'pb-5' : 'py-5']]">
    <loading-spinner v-if="loading" />
    <FabInvites
      :show="showInvites"
      :totalInvites="totalInvites"
      @onClose="handleInviteClose"
      @onSuccess="invitationList"
    />
    <DeletePost
      :show="show"
      @onClose="handleClose"
      @onDelete="deletePost"
    />
    <EditPost
      :updatePosts="updatePosts"
      :show="showPopup"
      :postData="postData"
      :updatePostIndex="updatePostIndex"
      @onClose="closePopup"
    />
    <TimeSlot
      v-if="fabId"
      :profilePic="tempProfile"
      :show="slotPopup"
      :fabUsername="fabUsername"
      :fabId="fabId"
      @onClose="closeSlotPopup"
    />
    <template v-if="fabId">
      <profile-cover
        v-if="fabId"
        :profileCover="tempCover"
        :profilePic="tempProfile"
        :fabId="fabId"
        :userId="userId"
        :getPostList="getPostList"
      />
    </template>
    <FeedMobile
      v-if="window.width < 768"
      :totalPublicPost="totalPublicPost"
      :totalExclusivePost="totalExclusivePost"
      :totalFollowers="totalFollowers"
      :totalFollowing="totalFollowing"
      :aboutFAB="about_you"
      :postsData="posts"
      :productsData="products"
      :fabUpcomingListData="fabUpcomingList"
      :profilePic="profile"
      :updatePosts="updatePosts"
      :getPostList="getPostList"
      :showDeletePopup="showDeletePopup"
      :showEditPopup="showEditPopup"
      :accountType="account_type"
      :fabId="fabId"
      :userId="userId"
      :showSlotPopup="showSlotPopup"
      :invites="invites"
      :totalInvites="totalInvites"
      :isfollowing="isfollowing"
      :fullName="fullname"
      :liveStreams="liveStreams"
      :displayTotalPosts="displayTotalPosts"
      @changeFollowStatus="followFab"
      @handleInvitePopUp="handleInvitePopUp"
    />
    <FeedTablet
      v-if="window.width >= 768 && window.width <= 992"
      :totalPublicPost="totalPublicPost"
      :totalExclusivePost="totalExclusivePost"
      :totalFollowers="totalFollowers"
      :totalFollowing="totalFollowing"
      :aboutFAB="about_you"
      :postsData="posts"
      :adsData="advertisements"
      :productsData="products"
      :fabUpcomingListData="fabUpcomingList"
      :profilePic="profile"
      :updatePosts="updatePosts"
      :getPostList="getPostList"
      :getAdsList="getAdsList"
      :showDeletePopup="showDeletePopup"
      :showEditPopup="showEditPopup"
      :accountType="account_type"
      :fabId="fabId"
      :userId="userId"
      :showSlotPopup="showSlotPopup"
      :invites="invites"
      :totalInvites="totalInvites"
      :fullName="fullname"
      :isfollowing="isfollowing"
      :liveStreams="liveStreams"
      :displayTotalPosts="displayTotalPosts"
      @handleInvitePopUp="handleInvitePopUp"
    />
    <b-row
      v-if="window.width > 992"
      class="mx-0"
    >
      <b-col
        lg="3"
        :class="['d-sm-none d-none d-md-none d-lg-block', style['user-details']]"
      >
        <p
          v-if="fabId"
          :class="['text-center text-capitalize pl-5', style['fab-full-name']]"
        >
          {{ fullname }}
        </p>
        <p
          v-if="fabId"
          :class="[
            style['user-description'],
            'font-weight-bold mt-4 text-center pb-0 mb-1',
            [showFullDesc ? '' : 'text-truncate'],
          ]"
        >
          {{ about_you !== "null" ? about_you : "" }}
        </p>
        <p
          v-if="fabId && about_you.length > 36"
          :class="[style['read-more-less'], 'p-0  mb-4 text-right']"
          @click="showDescription"
        >
          Read {{ showFullDesc ? "less" : "more" }}
        </p>
        <b-button
          v-if="account_type === '1' && fabId != '' || account_type === '2' && fabId != '' && fabId != userId"
          ref="button"
          variant="dark"
          :class="[
            style['follow-btn'],
            'btn-pop text-uppercase font-weight-bold mb-4',
          ]"
          squared
          block
          @click="followFab"
        >
          {{
            isfollowing === null || isfollowing === 0 ? "FOLLOW" : "UNFOLLOW"
          }}
        </b-button>
        <user-info
          v-if="account_type === '1' && fabId == ''"
          className="text-center mb-5"
          :username="username"
          :fullname="fullname"
          :profilePic="profile"
        />
        <reminder
          class="mb-4"
          style="display: none"
        />
        <h6
          v-if="account_type === '1' && fabId != '' || account_type === '2' && fabId != '' && fabId != userId"
          class="text-uppercase"
        >
          Available Slots
        </h6>
        <h6
          v-else
          class="text-uppercase"
        >
          Upcoming events
        </h6>
        <ul
          v-if="
            fabUpcomingList.length > 0 ||
              Object.keys(fabUpcomingList).length > 0
          "
          :class="['pr-1', style['event-list'], account_type === '1' && fabId != '' || account_type === '2' && fabId != '' && fabId != userId ? '' : style['upcoming-list'], 'list-unstyled']"
        >
          <li
            v-for="(upcomingEvent, aIndex) in fabUpcomingList"
            :key="aIndex"
            :class="
              aIndex == dateToday ? [style['active'], 'pl-3 mb-3'] : 'pl-3 mb-3'
            "
          >
            <strong
              class=""
            >{{
              dateToday == aIndex ? "today" : moment(aIndex).format("MM/DD")
            }}
            </strong>
            <div v-if="account_type === '1' && fabId != ''">
              <p
                v-for="bookslot of upcomingEvent"
                :key="bookslot.id"
                class="mb-0 font-open-sans"
              >
                {{ bookslot.call_type }} at {{ bookslot.start_time }}
              </p>
            </div>
            <div v-else>
              <p
                v-for="bookslot of upcomingEvent"
                :key="bookslot.id"
                class="mb-0 font-open-sans"
              >
                <template v-if="account_type === '1' || (account_type === '2' && bookslot.meet_with && bookslot.user_id == fabId)">
                  <span v-if="bookslot.call_type == 'Video Call'">
                    <span class="font-weight-bold small">
                      <!-- {{ bookslot.start_time }}  -->
                    </span>
                    {{ bookslot.name }} Video Call at {{ bookslot.start_time }}
                  </span>
                  <span v-else>
                    <!-- {{ bookslot.start_time }}  -->
                    {{ bookslot.name }} Live Streaming at
                    {{ bookslot.start_time }}</span>
                </template>
                <template v-if="account_type === '2' && bookslot.meet_with == undefined && bookslot.user_id == fabId">
                  <span v-if="bookslot.call_type == 'Video Call'">
                    <span class="font-weight-bold small">
                      <!-- {{ bookslot.start_time }}  -->
                    </span>
                    Video Call at {{ bookslot.start_time }}
                    <span v-if="bookslot.bookings_count == 1">with a fan</span>
                    <span v-else-if="bookslot.bookings_count > 1">with {{ bookslot.bookings_count }} fans</span>
                  </span>
                  <span v-else>
                    <!-- {{ bookslot.start_time }}  -->
                    Live Streaming at {{ bookslot.start_time }}
                    <!-- <span
                      v-if="bookslot.name"
                    >with {{ bookslot.name }}</span> -->
                    <span v-if="bookslot.bookings_count == 1">with a fan</span>
                    <span v-else-if="bookslot.bookings_count > 1">with {{ bookslot.bookings_count }} fans</span>
                  </span>
                </template>
              </p>
            </div>
          </li>
        </ul>
        <p
          v-else
          class="text-center"
        >
          No Event Available
        </p>
        <button
          v-if="(fabUpcomingList.length > 0 || Object.keys(fabUpcomingList).length > 0) && (account_type === '1' && fabId != '' && fabUpcomingList != '' || account_type === '2' && fabId != '' && fabId != userId && fabUpcomingList != '')"
          class="px-0 py-2 mb-4 btn-pop d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none"
          @click="showSlotPopup()"
        >
          Show More
        </button>
        <router-link
          v-else-if="account_type == '2'"
          class="px-0 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none"
          to="/meetings"
        >
          UPDATE CALENDAR
        </router-link>
        <h6
          v-if="account_type === '1' && fabId == 0 || account_type === '2' && fabId == userId"
          class="text-uppercase"
        >
          Invitations
        </h6>
        <ul
          v-if="
            invites &&
              ((invites.length > 0 || Object.keys(invites).length > 0) &&
                (account_type === '1' && fabId == 0 || account_type === '2' && fabId == userId)) 
          "
          :class="[style['event-list'], 'list-unstyled']"
        >
          <li
            v-for="(invite, index) in invites"
            :key="index"
            :class="
              dateToday === moment(invite.date).format('YYYY-MM-DD')
                ? [style['active'], 'pl-3 mb-3']
                : 'pl-3 mb-3'
            "
          >
            <strong class="">
              {{
                dateToday === moment(invite.date).format("YYYY-MM-DD")
                  ? `today, ` + moment(invite.date).format("hh:mm a")
                  : moment(invite.date).format("MM/DD")
              }}
            </strong>
            <div>
              <p class="mb-0 font-open-sans">
                Invitation from {{ invite.fab_name }}
              </p>
            </div>
          </li>
        </ul>
        <ul
          v-else-if="
            invites &&
              (invites.length === 0 || Object.keys(invites).length === 0) && (account_type === '1' && fabId == 0 || account_type === '2' && fabId == userId)
          "
        >
          No Invitations Available
        </ul>
        <button
          v-if="totalInvites.length > 0 && (account_type === '1' && fabId == 0 || account_type == '2' && fabId == userId)"
          class="px-0 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none"
          @click="handleInvitePopUp"
        >
          Show More
        </button>
      </b-col>
      <b-col
        lg="6"
        sm="8"
      >
        <b-row
          v-if="fabId"
          :class="[style['followers-count'], 'font-weight-bold ml-3']"
        >
          <b-col> {{ displayTotalPosts }} posts </b-col>
          <b-col
            :class="[style['cursor-pointer']]"
            @click="
              () =>
                followersAccountType !== ''
                  ? $router.push(
                    `/followers-following/${followersAccountType}`
                  )
                  : $router.push('/followers-following')
            "
          >
            {{ totalFollowers }} followers
          </b-col>
          <b-col
            :class="[style['cursor-pointer']]"
            @click="
              () =>
                followersAccountType !== ''
                  ? $router.push(
                    `/followers-following/${followersAccountType}`
                  )
                  : $router.push('/followers-following')
            "
          >
            {{ totalFollowing }} following
          </b-col>
          <b-col
            lg="6"
            style="margin-left: 92%"
          >
            <p>
              <b-button
                v-if="account_type === '1' && getExclusiveContent === false"
                ref="button"
                variant="dark"
                :class="[
                  style['exclusive-content-btn'],
                  'btn-pop text-uppercase font-weight-bold ml-5',
                ]"
                squared
                block
                @click="() => $router.push('/booking/content')"
              >
                Get Exclusive Content
              </b-button>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <live-stream :liveStreams="liveStreams" />
          </b-col>
        </b-row>
        <AddPost
          v-if="fabId === userId && account_type === '2'"
          class="mb-4"
          :updatePosts="updatePosts"
        />
        <b-row class="justify-content-center mx-0">
          <b-col cols="12">
            <b-tabs
              content-class="mt-5"
              :class="style['feed-tabs']"
              align="center"
            >
              <b-tab
                v-if="activePostTab"
                title="Posts"
                active
                @click="handleTab('post')"
              >
                <template v-if="posts.length">
                  <div
                    v-for="(post, pIndex) in posts"
                    :key="post.id+'-'+post.type"
                  >
                    <post
                      :className="[
                        {
                          'mb-4': pIndex < posts.length - 1,
                        },
                      ]"
                      :postIndex="pIndex"
                      :postById="post.post_by_id"
                      :profilePic="profile"
                      :loveByUser="post.loveByUser"
                      :username="username"
                      :postBy="post.postBy"
                      :postFees="post.postFees"
                      :postUsername="post.username"
                      :postProfilePic="post.profile"
                      :postTime="new Date(post.postTime)"
                      :postCaption="post.postCaption"
                      :postPic="post.postPic"
                      :loves="post.loves"
                      :comments="post.comments"
                      :exclusive="post.exclusive"
                      :isExclusivePost="post.isExclusivePost"
                      :postId="post.id"
                      :getPostList="getPostList"
                      :showDeletePopup="showDeletePopup"
                      :showEditPopup="showEditPopup"
                      :accountType="account_type"
                      :postData="post"
                      :userId="userId"
                      :updatePosts="updatePosts"
                      :fabId="fabId"
                      :postType="post.post_type"
                      :slotDetails="post.slotDetails"
                    />
                  </div>
                </template>
                <template v-else>
                  <no-post
                    v-if="nopostlist"
                    :style="account_type === '1' && fabId ? 'margin-top:55px' : ''"
                  />
                </template>
              </b-tab>
              <b-tab
                title="Events"
                @click="handleTab('event')"
              >
                <template v-if="unbookedSlots.length">
                  <div
                    v-for="(post, pIndex) in unbookedSlots"
                    :key="post.id+'-'+post.type"
                  >
                    <post
                      :className="[
                        {
                          'mb-4': pIndex < unbookedSlots.length - 1,
                        },
                      ]"
                      :postIndex="pIndex"
                      :postById="post.post_by_id"
                      :profilePic="profile"
                      :loveByUser="post.loveByUser"
                      :username="username"
                      :postBy="post.postBy"
                      :postFees="post.postFees"
                      :postUsername="post.username"
                      :postProfilePic="post.profile"
                      :postTime="new Date(post.postTime)"
                      :postCaption="post.postCaption"
                      :postPic="post.postPic"
                      :loves="post.loves"
                      :comments="post.comments"
                      :exclusive="post.exclusive"
                      :isExclusivePost="post.isExclusivePost"
                      :postId="post.id"
                      :getPostList="getPostList"
                      :showDeletePopup="showDeletePopup"
                      :showEditPopup="showEditPopup"
                      :accountType="account_type"
                      :postData="post"
                      :userId="userId"
                      :updatePosts="updatePosts"
                      :fabId="fabId"
                      :postType="post.post_type"
                      :slotDetails="post.slotDetails"
                    />
                  </div>
                </template>
                <template v-else>
                  <no-event
                    v-if="noEventList"
                    :style="account_type === '1' && fabId ? 'margin-top:55px' : ''"
                  />
                </template>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        lg="3"
        sm="4"
        :class="[style['right-pannel-feed'], 'd-md-block']"
      >
        <request-sale
          v-if="account_type === '1' || account_type === '2' && fabId != userId"
          :className="['d-md-block']"
        />
        <div
          v-if="fabId"
          :class="style['right-section-feed']"
        >
          <p class="font-weight-bold">
            RECENTLY ADDED:
          </p>
          <template v-if="products.length > 0">
            <shop-card-sm
              v-for="(product, pIndex) in products"
              :key="`product${pIndex}`"
              :productImage="product.image"
              :productTitle="product.title"
              :productPrice="product.price"
              :productId="product.id"
              :productUserId="product.user_id"
            />
          </template>
          <template
            v-else
            class="text-center"
          >
            <p v-if="account_type === '2'">
              No product available
            </p>
          </template>
          <b-button
            v-if="products.length > 0"
            ref="button"
            variant="dark"
            :class="['btn-pop text-uppercase font-weight-bold']"
            squared
            block
            @click="() => $router.push(`/products/${encodeFabId}`)"
          >
            See the store
          </b-button>
        </div>
        <div v-if="account_type === '1' && fabId == ''">
          <advertisement
            v-for="(advertisement, aIndex) in advertisements"
            :key="`advertisement${aIndex}`"
            :advImage="advertisement.advImage"
            :advText="advertisement.advText"
            :advId="advertisement.advId"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// let newPostArray = [];
import UserInfo from "../../components/User/UserInfo.vue";
import Post from "../../components/Feed/Post.vue";
import ProfileCover from "../../components/Feed/ProfileCover.vue";
import AddPost from "../../components/Feed/AddPost.vue";
import ShopCardSm from "../../components/Feed/ShopCardSm.vue";
import RequestSale from "../../components/Feed/RequestSale.vue";
import Advertisement from "../../components/Advertisements/Advertisement.vue";
import NoPost from "../../components/Feed/NoPost.vue";
import style from "../../styles/feed.module.scss";
import FeedTablet from "./FeedTablet.vue";
import FeedMobile from "./FeedMobile.vue";
import Reminder from "../../components/Feed/ReminderPopup.vue";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import {
    _sendResponse,
    dateFormat,
    timeFormat,
} from "../../js/common-function";
import { APIUrlList } from "../../js/api_url";
import { getInvitationList } from "../../api/meeting";
import { showDays } from "../../api/feed";
import DeletePost from "../../components/Feed/DeletePost.vue";
import EditPost from "../../components/Feed/EditPost.vue";
import TimeSlot from "../../components/Feed/TimeSlot";
import Vue from "vue";
import LoadingSpinner from "../../components/Common/LoadingSpinner.vue";
import moment from "moment-timezone";
import FabInvites from "../../components/Feed/FabInvites";
import LiveStream from "../../components/Feed/LiveStream.vue";
import NoEvent from "../../components/Feed/NoEvent.vue";

export default {
    components: {
        UserInfo,
        Post,
        ProfileCover,
        AddPost,
        ShopCardSm,
        RequestSale,
        Advertisement,
        NoPost,
        FeedTablet,
        FeedMobile,
        Reminder,
        DeletePost,
        EditPost,
        LoadingSpinner,
        TimeSlot,
        FabInvites,
        LiveStream,
        NoEvent
    },
    data() {
        return {
            style,
            loading: false,
            show: false,
            username: localStorage.getItem("username"),
            fullname: "",
            profileName:
        this.$route.params.username != undefined
            ? this.$route.params.username
            : "",
            userId: 0, // gab or fab user id
            fabUsername: "",
            fabId: 0,
            profile: "",
            tempCover: "",
            tempProfile: "",
            account_type: localStorage.getItem("account-type"),
            window: {
                width: 0,
                height: 0,
            },
            posts: [],
            products: [],
            totalPublicPost: 0,
            totalExclusivePost: 0,
            totalPosts: 0,
            totalFollowers: 0,
            totalFollowing: 0,
            totalPages: [],
            advertisements: [],
            about_you: "",
            scrolledToBottom: false,
            pageRefresh: false,
            counter: 1,
            fabUpcomingList: [],
            eventDateFormat: dateFormat,
            eventTimeFormat: timeFormat,
            postId: 0,
            next_page: "",
            next_page_load: true,
            next_page_url: "",
            showPopup: false,
            slotPopup: false,
            postData: {},
            updatePostIndex: 0,
            isfollowing: 0,
            deleteType: 0,
            showFullDesc: false,
            encodeFabId: "",
            nopostlist: false,
            getExclusiveContent: false,
            totalInvites: [],
            invites: [],
            showInvites: false,
            dateToday: moment(new Date()).format("YYYY-MM-DD"),
            unbookedSlots: [],
            followersAccountType: "",
            allPosts: [],
            liveStreams: [],
            activePostTab: true,
            disabled_posts: false,
            noEventList: false,
            eventPage: 1,
            currentActivtab: "post",
            totalEvents: 0,
            displayTotalPosts: 0
        };
    },
    watch: {
        totalPosts(value) {
            this.displayTotalPosts = this.countFormatter(value, 1);
        }
    },
    async created() {
        if (this.account_type === "1" || this.account_type === "2" && this.$route.params.username && this.$route.params.username != this.username) {
            this.followersAccountType = this.$route.params.username;
        }
        window.addEventListener("resize", this.handleResize);
        window.addEventListener("scroll", this.scroll);
        this.handleResize();
        this.getUserProfile();
        this.invitationList();
    },
    mounted() {
        window.addEventListener("scroll", this.scroll);
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
        window.removeEventListener("scroll", this.scroll);
    },
    methods: {
        async invitationList() {
            let invitations = await getInvitationList();
            let newInvites = [];
            if (invitations && Object.keys(invitations).length > 0) {
                invitations.map((value) => {
                    let start_date = new Date(value.start_time.replace(/-/g, "/")); //+' UTC'
                    let end_date = new Date(value.end_time.replace(/-/g, "/")); //+' UTC'
                    let invite_date = new Date(
                        value.invitations_created_at.replace(/-/g, "/") + " UTC"
                    );
                    let objToPush = {
                        call_type: value.call_type,
                        date: invite_date,
                        end_time: end_date,
                        start_time: start_date,
                        fab_id: value.fab_id,
                        fab_name: value.fab_name,
                        id: value.id,
                        invitation_id: value.invitation_id,
                        profile_pic_url: value.profile_pic_url,
                        profile_pic: value.profile_pic,
                        slot_id: value.slot_id,
                        time_zone: value.time_zone,
                        time_zone_offset: value.time_zone_offset,
                    };
                    newInvites.push(objToPush);
                });
                this.totalInvites = newInvites;
                this.invites = newInvites.slice(0, 3);
            }
        },
        scroll() {
            var body = document.body,
                html = document.documentElement;

            var height = Math.max(
                body.scrollHeight,
                body.offsetHeight,
                html.clientHeight,
                html.scrollHeight,
                html.offsetHeight
            );

            var middle_height = height / 2;

            if (window.scrollY > middle_height) {
                this.scrolledToBottom = true;
                this.pageRefresh = true;
                if (this.currentActivtab == "post"){
                    this.counter = this.counter + 1;
                    if (this.next_page_load === true) {
                        this.getPostList(this.counter);
                    }
                } else if (this.currentActivtab == "event"){
                    if (this.eventPage < this.totalEvents){
                        this.eventPage = this.eventPage+1;
                        this.getUnbookedSlots();
                    }
                    
                }
                
            }
        },
        renderCover() {
            if (
                localStorage.getItem("account-type") !== null &&
        localStorage.getItem("account-type") != 1
            ) {
                return true;
            }
        },
        showEditPopup(data, postIndex) {
            this.postData = data;
            this.showPopup = true;
            this.updatePostIndex = postIndex;
        },
        showDeletePopup(id, exclusive) {
            this.postId = id;
            this.deleteType = exclusive;
            this.show = true;
        },
        showSlotPopup() {
            this.slotPopup = true;
        },
        handleClose() {
            this.show = false;
        },
        closePopup() {
            this.showPopup = false;
        },
        closeSlotPopup() {
            this.slotPopup = false;
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        getLoginUserProfile() {
            this.loading = true;
            this.posts = [];
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .get(API_BASE_URL + APIUrlList.PROFILEDETAILS)
                .then((response) => {
                    this.loading = false;
                    this.userId = response.data.data.id;
                    this.profile = response.data.data.profile_pic_url;
                })
                .catch((error) => {
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                });
        },
        getUserProfile() {
            this.loading = true;
            this.posts = [];
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .get(
                    API_BASE_URL +
            APIUrlList.PROFILEDETAILS +
            "/" +
            this.$route.params.username
                )
                .then((response) => {
                    this.fullname = `${response.data.data.name} ${response.data.data.surname}`;
                    this.about_you =
            response.data.data.about_you !== null
                ? response.data.data.about_you
                : "";
                    this.tempCover = response.data.data.cover_image;
                    this.tempProfile = response.data.data.profile_pic_url;
                    this.isfollowing = response.data.data.is_follow;

                    if (response.data.data.account_type === 2) {
                        this.fabId = response.data.data.id;
                        localStorage.setItem("fabUsername", this.$route.params.username);
                        localStorage.setItem(
                            "fabProfilePic",
                            response.data.data.profile_pic_url
                        );
                        localStorage.setItem(
                            "fabName",
                            `${response.data.data.name} ${response.data.data.surname}`
                        );
                        localStorage.setItem("fabId", response.data.data.id);
                        localStorage.setItem(
                            "fabPostFees",
                            response.data.data.exclusive_post_fee
                        );
                        this.encodeFabId = window.btoa(response.data.data.id);
                        this.totalPublicPost = response.data.data.public_post;
                        this.totalExclusivePost = response.data.data.exclusive_post;
                        this.totalPosts= response.data.data.public_post+response.data.data.exclusive_post;
                        this.totalFollowers = this.countFormatter(response.data.data.followers, 1);
                        this.totalFollowing = this.countFormatter(response.data.data.following, 1)
                            ? response.data.data.following
                            : 0;

                        this.getProductList();

                        this.getPostList();

                        this.upcomingList();
                        this.getLiveStreaming();
                    } else {
                        this.fabId = 0;

                        this.getPostList();

                        this.getAdsList();
                        this.upcomingList();
                        this.getLiveStreaming();
                    }
                    
                    

                    this.getLoginUserProfile();
                    // this.loading = false;
                })
                .catch((error) => {
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                    window.location.href = "/logout";
                });
        },
        handleTab(type){
            if (type == 'post'){
                this.posts = [];
                this.currentActivtab = "post";
                this.getDefaultPostList();
            } else if (type == 'event'){
                this.unbookedSlots = [];
                this.currentActivtab = "event";
                this.eventPage = 1;
                this.getUnbookedSlots();
            }
        },
        getUnbookedSlots() {
            if (this.eventPage == 1){
                this.loading = true;
            }
            const slot_url = API_BASE_URL + APIUrlList.UNBOOKEDSLOTS;
            var formData = {
                fab_id: this.fabId,
                is_pagination: 1,
                page: this.eventPage
            };

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
            // .post(API_BASE_URL + APIUrlList.POSTLIST)
                .post(slot_url, formData)
                .then((response) => {
                    var array = response.data.data.data;
                    if (array && array.length > 0) {
                        this.noEventList = false;
                        array.map((value) => {
                            var obj = {};
                            obj["id"] = value.id;
                            obj["post_by_id"] = value.fab["id"];
                            obj["post_type"] = 3; //post type 3 as available slot/call just to put a condition(no such specification in DB)
                            obj["username"] = value.fab["username"];
                            obj["postBy"] = `${value.fab["name"]} ${value.fab["surname"]}`;
                            obj["profile"] = value.fab["profile_pic_url"];
                            obj["postTime"] = value.created_at;
                            obj["postPic"] = "";
                            obj["postPicPath"] = "";
                            obj["isExclusivePost"] = 0;
                            obj["exclusive"] = 1;
                            obj["slotDetails"] = value;
                            this.unbookedSlots.push(obj);


                        });
                    } else {
                        this.noEventList = true;
                    }
                    if  (response.data.data && response.data.data.last_page){
                        this.totalEvents = response.data.data.last_page;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    // handle authentication and validation errors here
                    this.loading = false;
                    _sendResponse("error", error);
                });
        },
        getDefaultPostList(){
            this.fetchPostList(false); //for tabs to load from first page of posts 
        },
        getPostList() {
            this.fetchPostList(true); //get post pages by pagination for scrolls
        },
        fetchPostList(pagination){
            //fetch post list based on pagination required
            this.next_page_load = false;

            var post_url = API_BASE_URL + APIUrlList.POSTLIST;
            if (this.next_page_url != "" && pagination == true) {
                post_url = this.next_page_url;
            } 
            if (this.posts.length == 0) {
                this.loading = true;
            }
            var formData = {
                fab_id: this.fabId,
            };
            //this.posts = [];
            // newPostArray = [];
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
            // .post(API_BASE_URL + APIUrlList.POSTLIST)
                .post(post_url, formData)
                .then((response) => {
                    var array = response.data.data.data;
                    if (array.length > 0) {
                        this.nopostlist = false;
                        array.map((value) => {
                            var obj = {};
                            obj["id"] = value.id;
                            obj["post_by_id"] = value.post_by["id"];
                            obj["post_type"] = value.post_type;
                            obj["username"] = value.post_by["username"];
                            obj["postFees"] = value.post_by["fab_settings"];
                            obj[
                                "postBy"
                            ] = `${value.post_by["name"]} ${value.post_by["surname"]}`;
                            obj["profile"] = value.post_by["profile_pic_url"];
                            obj["postTime"] = value.created_at;
                            obj["postCaption"] = value.message;
                            if (value.post_media) {
                                obj["postPic"] = value.post_media["full_media_url"];
                                obj["postPicPath"] = value.post_media["media_url"];
                            } else {
                                obj["postPic"] = "";
                                obj["postPicPath"] = "";
                            }
                            if (value.post_like_by_user === 1) {
                                obj["loveByUser"] = true;
                            } else {
                                obj["loveByUser"] = false;
                            }
                            obj["loves"] = value.post_likes;

                            var is_exclusive_post = 1;

                            if (value.is_exclusive_post === 1) {
                                if (value.access_exclusive_post === 0) {
                                    is_exclusive_post = 0;
                                } else if (value.access_exclusive_post === 1) {
                                    this.getExclusiveContent = true;
                                }
                            }

                            obj["exclusive"] = is_exclusive_post;
                            obj["isExclusivePost"] = value.is_exclusive_post;

                            var comments = value.post_comments;
                            var comments_data = [];
                            comments.map((comments) => {
                                var comobj = {};
                                comobj["id"] = comments.id;
                                comobj["comment"] = comments.comment;
                                var author_data = {
                                    id: `${comments.comment_users["id"]}`,
                                    username: `${comments.comment_users["name"]} ${comments.comment_users["surname"]}`,
                                    profile: comments.comment_users["profile_pic_url"],
                                };
                                comobj["post_user_id"] = comments.user_id;
                                comobj["commentTime"] = comments.created_at;
                                comobj["post_user_id"] = comments.user_id;
                                comobj["author"] = author_data;
                                comobj["loves"] = comments.comment_likes;
                                var comment_like_by_user = false;
                                if (comments.comment_like_by_user === 1) {
                                    comment_like_by_user = true;
                                }
                                comobj["loveByUser"] = comment_like_by_user;

                                comments_data.push(comobj);
                            });
                            obj["comments"] = comments_data;
                            
                            this.posts.push(obj);
                            

                        });
                        // this.posts = newPostArray;
                        var next_page_url = response.data.data.next_page_url;
                        if (next_page_url != null) {
                            this.next_page_load = true;
                            this.next_page_url = response.data.data.next_page_url;
                        } else {
                            this.next_page_url = "";
                            this.next_page_load = false;
                        }
                    } else {
                        this.nopostlist = true;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    // handle authentication and validation errors here
                    this.loading = false;
                    _sendResponse("error", error);
                });
        },
        deletePost() {
            let me = this;
            this.loading = true;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .get(API_BASE_URL + APIUrlList.DELETEPOST + `/${this.postId}`)
                .then((response) => {
                    if (response.data.data.is_exclusive_post === 1) {
                        this.totalExclusivePost = this.totalExclusivePost - 1;
                    } else if (response.data.data.is_exclusive_post === 0) {
                        this.totalPublicPost = this.totalPublicPost - 1;
                    }
                    this.totalPosts = this.totalPosts - 1;
                    this.loading = false;
                    if (response.data.status) {
                        _sendResponse("success", response.data.message);
                        this.posts.findIndex(function (current, index) {
                            if (current.id === me.postId) {
                                me.posts.splice(index, 1);
                            }
                        });
                    } else {
                        _sendResponse("error", response.data.message);
                    }
                });
        },
        getProductList() {
            // this.loading = true;
            var formdata = {
                is_recently: 1,
                fab_id: this.fabId,
            };
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .post(API_BASE_URL + APIUrlList.PRODUCTLIST, formdata)
                .then((response) => {
                    // this.loading = false;
                    var productList = response.data.data.data;
                    for (var i = 0; i < productList.length; i++) {
                        if (productList[i].status !== 3) {
                            var pObj = {};
                            pObj["id"] = productList[i].id;
                            pObj["user_id"] = productList[i].user_id;
                            pObj["image"] =
                productList[i].product_images[0] !== undefined
                    ? productList[i].product_images[0].p_image
                    : "";
                            pObj["title"] = productList[i].name;
                            pObj["price"] = productList[i].price;
                            this.products.push(pObj);
                        }
                    }
                })
                .catch((error) => {
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                });
        },
        upcomingList() {
            // this.loading = true;
            var curr = new Date(); // get current date
            var start_date = moment(curr).format("MM/DD/YYYY");
            var formData = {};
            if (this.fabId) {
                formData = {
                    fab_id: this.fabId,
                    start_date: start_date,
                };
            } else {
                formData = {
                    start_date: start_date,
                };
            }
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .post(API_BASE_URL + APIUrlList.GETFABUPCOMINGEVENT, formData)
                .then((response) => {
                    // this.loading = false;
                    var array = response.data.data;
                    const showMoreSlot = [];
                    if (array) {
                        array.map((value) => {
                            var obj = {};
                            var start_date = new Date(value.start_time.replace(/-/g, "/")); //+' UTC'
                            if (
                                localStorage.getItem("account-type") !== null &&
                localStorage.getItem("account-type") == 2
                            ) {
                                start_date = new Date(value.start_time.replace(/-/g, "/"));
                            }

                            start_date = start_date.toString();

                            var today = moment().format("DD/MM/YY");
                            var video_call_date = moment(start_date).format("DD/MM/YY");
                            var weekDayName = moment(start_date).format("dddd");
                            if (today === video_call_date) {
                                weekDayName = "Today";
                            }

                            obj["day"] = weekDayName;
                            obj["full_date"] = moment(start_date).format("YYYY-MM-DD");
                            obj["start_time"] = moment(start_date).format("h:mm a");
                            obj["slot_id"] = value.slot_id;
                            obj["bookings_count"] = value.bookings_count;
                            if (this.account_type === "1" && value.meet_with || this.account_type === "2" && value.meet_with && value.user_id == this.fabId) {
                                obj["name"] = value.meet_with.name + " " + value.meet_with.surname;
                            } else if (
                                this.account_type === "2" && value.fanName && value.fanSurname
                            ) {
                                obj["name"] = value.fanName + " " + value.fanSurname;
                            }
                            var dateToday = new Date();
                            obj["date_today"] = moment(dateToday).format("YYYY-MM-DD");

                            var call_type = "Video Call";
                            if (value.call_type == 2) {
                                call_type = "Streaming";
                            }
                            obj["call_type"] = call_type;
                            obj["user_id"] = value.user_id;
                            obj["meet_with"] = value.meet_with;
                            const filter = showMoreSlot.filter((item) => item.slot_id == value.slot_id);

                            if (filter.length == 0){
                                if (this.account_type === "1" && this.fabId != "" || this.account_type === "2" && this.fabId != "" && this.fabId != this.userId) {
                                    if (value.call_type === 2) {
                                        showMoreSlot.push(obj);
                                    } else {
                                        if (value["booking"] == null) {
                                            showMoreSlot.push(obj);
                                        }
                                    }
                                } else {
                                    showMoreSlot.push(obj);
                                }
                            }

                            // showMoreSlot.push(obj);
                        });
                    }

                    var mainArr = this.groupBy(showMoreSlot, "full_date");
                    // console.log(mainArr,'12321');
                    // console.log(moment(dateToday).format('yyyy-MM-DD'),'12321');
                    this.fabUpcomingList = mainArr;
                })
                .catch((error) => {
                    console.error(error);
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                });
        },
        updatePosts(data, action, postIndex = "") {
            let comments_data = [];
            data.post_comments.length > 0
                ? data.post_comments.map((comments) => {
                    var comobj = {};
                    comobj["id"] = comments.id;
                    comobj["comment"] = comments.comment;
                    var author_data = {
                        username:
                comments.comment_users["name"] +
                " " +
                comments.comment_users["surname"],
                        profile: comments.comment_users["profile_pic_url"],
                    };
                    comobj["post_user_id"] = comments.user_id;
                    comobj["commentTime"] = comments.created_at;
                    comobj["author"] = author_data;
                    comobj["loves"] = comments.comment_likes;
                    var comment_like_by_user = false;
                    if (comments.comment_like_by_user === 1) {
                        comment_like_by_user = true;
                    }
                    comobj["loveByUser"] = comment_like_by_user;

                    comments_data.push(comobj);
                })
                : [];

            let objToAdd = {
                id: data.id,
                username: data.post_by.name,
                profile: data.post_by.profile_pic_url,
                postTime: data.created_at,
                postCaption: data.message,
                postPic: data.post_media != null ? data.post_media.full_media_url : "",
                loveByUser: data.post_like_by_user === 1 ? true : false,
                loves: data.post_likes,
                post_by_id: data.post_by.id,
                exclusive: 1,
                postBy: `${data.post_by.name} ${data.post_by.surname}`,
                isExclusivePost: data.is_exclusive_post,
                post_type: data.post_type,
                comments: comments_data,
            };
            if (action === "add") {
             
                this.posts.unshift(objToAdd);
                // this.posts = newPostArray;
                

            } else {
                Vue.set(this.posts, postIndex, objToAdd);
            }

            if (action === "add") {
                if (data.is_exclusive_post === 1) {
                    this.totalExclusivePost = parseInt(this.totalExclusivePost) + 1;
                } else {
                    this.totalPublicPost = parseInt(this.totalPublicPost) + 1;
                }
                this.totalPosts = parseInt(this.totalPosts)+1;
            }
        },
        groupBy(xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        getAdsList() {
            // this.loading = true;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .get(API_BASE_URL + APIUrlList.GETADSLIST)
                .then((response) => {
                    // this.loading = false;
                    var array = response.data.data;
                    const newAds = [];
                    if (array) {
                        array.map((value) => {
                            var obj = {};
                            obj["advId"] = value.id;
                            obj["advText"] = value.title;
                            obj["advImage"] = value.media_file_url;
                            obj["advUrl"] = value.url;
                            newAds.push(obj);
                        });
                        this.advertisements = newAds;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                });
        },
        followFab() {
            this.loading = true;
            var status = 1;
            if (this.isfollowing === 1) {
                status = 0;
            }
            var formData = {
                fab_id: this.fabId,
                status: status,
            };
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .post(API_BASE_URL + APIUrlList.FOLLOWFAB, formData)
                .then((response) => {
                    this.loading = false;
                    this.isfollowing =
            response.data.data[0] && response.data.data[0].status
                ? response.data.data[0].status
                : status;
                })
                .catch((error) => {
                    console.error(error);
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                });
        },
        showDescription() {
            this.showFullDesc = !this.showFullDesc;
        },
        async handleInviteClose() {
            this.showInvites = false;
            let invitations = await getInvitationList();
            this.totalInvites = invitations;
            this.invites = invitations;
        },
        handleInvitePopUp() {
            this.showInvites = true;
        },
        getLiveStreaming() {
            let formFabId = this.fabId;
            if (formFabId == 0){
                formFabId = null;
            }
            var formData = {
                fab_id: formFabId,
            };
            const stream_url = API_BASE_URL + APIUrlList.LIVESTREAMLIST;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
            // .post(API_BASE_URL + APIUrlList.POSTLIST)
                .post(stream_url, formData)
                .then((response) => {
                    var array = response.data.data;
                    const streaming = [];
                    if (array) {
                        array.map((val) => {
                            var obj = {};
                            obj["id"] = val.id;
                            obj["call_type"] = val.call_type;
                            obj["stream_type"] =
                      val.stream_type === 1
                          ? "Private"
                          : val.stream_type === 2
                              ? "Public"
                              : "";
                            //var start_date_time = moment(val.date).format('YYYY-MM-DD')+' '+val.start_time;
                            var start_date_time = moment(val.start_time).format(
                                "YYYY-MM-DD HH:mm:ss"
                            );
                            var start_date = new Date(start_date_time.replace(/-/g, "/"));
                            start_date = start_date.toString();
                            //var end_date_time = moment(val.date).format('YYYY-MM-DD')+' '+val.end_time;
                            var end_date_time = moment(val.end_time).format("YYYY-MM-DD HH:mm:ss");
                            var end_date = new Date(end_date_time.replace(/-/g, "/"));
                            end_date = end_date.toString();
                            obj["start"] = moment(start_date).format("YYYY-MM-DD HH:mm:ss");
                            obj["end"] = moment(end_date).format("YYYY-MM-DD HH:mm:ss");
                            obj["timezone"] = val.time_zone;


                            var Sdate = new Date(val.start_time).toString();
                            //let original_start_date = changeTimezone(Sdate, val.time_zone);
                            obj["start_original_time"] = moment(Sdate).format("hh:mm a");
                            obj["start_original_date"] = moment(Sdate).format("DD-MMM-yyyy");
                            obj["day"] = moment(Sdate).format("dddd");
                            var Edate = new Date(val.end_time).toString();
                            //let original_end_date = changeTimezone(Edate, val.time_zone);
                            obj["end_original_time"] = moment(Edate).format("hh:mm a");

                            let date1 = new Date();
                            let date2 = new Date(start_date);
                            let date3 = new Date(end_date);
                            let remainig = showDays(date1, date2, date3);
                            obj["remaining_time"] = remainig !== null ? remainig : [];
                            obj["call_status"] = val.status;
                            obj["fab"] = val.fab;
                            obj["booking"] = val.booking;
                            streaming.push(obj);
                        });
                    }
                    this.liveStreams = streaming;
                }).catch((error) => {
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                });

        },
        countFormatter(count, digits) {
            let num = parseFloat (count);
            const lookup = [
                { value: 1, symbol: "" },
                { value: 1e3, symbol: "k" },
                { value: 1e6, symbol: "M" },
                { value: 1e9, symbol: "G" },
                { value: 1e12, symbol: "T" },
                { value: 1e15, symbol: "P" },
                { value: 1e18, symbol: "E" }
            ];
            const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
            var item = lookup.slice().reverse().find(function(item) {
                return num >= item.value;
            });

            return item ? (num / item.value).toFixed(digits).replace(rx, "")+item.symbol : "0";
        }
    },
};
</script>
